import React, {useContext} from "react";
import { useParams } from "react-router-dom";
import { CartContext } from "../../context/cart";
import axios from "../../api/axios";

const QuickCheckout = () => {
  const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal } =
    useContext(CartContext);

    let {productId, size} = useParams()

    const getStripeUrl = async () => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // const body = [productId]
      const body = ["65f1b1088ddd79b88dace33e", "65f1b1088ddd79b88dace33e"]
      try {
        const response = await axios.post("/test/pay",body, headers);
        if (response.status == 200) {
          // setUserData(response.data)
          console.log(response.data)
          window.location.href = response.data.url
        }
      } catch (err) {
        console.log(err);
      }
    };

   
  return (
    <div>
      <h1>QuickCheckout</h1>
      <p>{productId}</p>
      <p>{size}</p>
      <button className="m-5 p-3 rounded-md border border-gray-300 bg-gray-50"
      
      onClick={() => {getStripeUrl()}}>
        <h1>CHECKOUT</h1>
      </button>
    </div>
  );
};

export default QuickCheckout;
