import React from 'react'

const NewFooter = () => {
  return (
    <footer className="text-gray-600 body-font border-t border-gray-200">
    <div className="container px-5 pt-12 pb-12 lg:pb-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <img
            src={require("../../assets/images/favicon-png.png")}
            className="w-9 aspect-square"
            alt=""
          />
          <span className="ml-3 text-xl">no context</span>
        </a>
        <p className="mt-2 text-sm text-gray-500">
          Vers une mode upcyclée, unique et responsable.
        </p>
        <div className="flex justify-center">
          <div className="px-2 py-2 rounded-lg bg-black mt-2 shadow-lg w-fit">
            <p className="text-[8px] text-white font-bold">
              made with 🤍 by sam
            </p>
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        
          <div className="lg:w-1/4 md:w-1/2 w-full px-4" >
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              VETEMENTS
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Hauts"
                >Hauts</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Pantalons">
                  Pantalons
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Vestes">Vestes</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Chaussures">
                  Chaussures
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4" >
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              AUTRE
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Accessoires">Accessoires</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Accessoires">
                  Sacs à main
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Accessoires">Bijoux</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/catalog?target=Accessoires">
                  Décoration
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4" >
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              A PROPOS
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/contact">Contact</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/orders">
                  Suivre votre commande
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/contact">FAQ</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/investors">
                  Relation investisseurs
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4" >
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              LEGAL
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800"
                href="/cgv">Conditions d'utilisation</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800" href="/cgv">
                  Conditions de vente
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800" href="/cgv">Mentions légales</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800" href="/cgv">
                  Collecte de données
                </a>
              </li>
            </nav>
          </div>
      
      </div>
    </div>
    <div className="bg-gray-100">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-gray-500 text-sm text-center sm:text-left">
          © 2024 No Context —
          <a
            href="https://instagram.com/nocontext_upcycling/"
            rel="noopener noreferrer"
            className="text-gray-600 ml-1"
            target="_blank"
          >
            @nocontext_upcycling
          </a>
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <a className="text-gray-500">
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>

          <a className="ml-3 text-gray-500">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a className="ml-3 text-gray-500">
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              ></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </span>
      </div>
    </div>
  </footer>
  )
}

export default NewFooter