import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import ProductLayoutTemp from "../components/products/ProductLayoutTemp";
import { CartContext } from "../context/cart";
import SideCart from "../components/layout/SideCart";
import Header from "../components/layout/Header";
import { DotLoader } from "react-spinners";
import HeaderAlt from "../components/layout/HeaderAlt";

const Product = () => {
  let { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productInfo, setProductInfo] = useState(null);

  const {
    sideCartOpen,
    setSideCartOpen,
    addToCart,
    cartItems,
    removeFromCart,
    clearCart
  } = useContext(CartContext);

  const getProductInfo = async () => {
    setIsLoading(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(`/products/info/${productId}`, headers);
      if (response.status == 200) {
        setProductInfo(response.data);
      }
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, []);
  return (
    <div className="relative">
      <div className="w-full flex items-center justify-center">
        <HeaderAlt backgroundColor="white" />
      </div>
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      {error ? (
        <div>
          <h1>ERROR LAYOUT</h1>
        </div>
      ) : isLoading ? (
        <div class="pt-[10vh] w-screen h-screen flex-1 items-center justify-center flex">
          <DotLoader
            color={"black"}
            loading={isLoading}
            // cssOverride={override}
            size={75}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : productInfo !== null ? (
        <ProductLayoutTemp
          productInfo={productInfo}
          addToCart={addToCart}
          cartItems={cartItems}
          removeFromCart={removeFromCart}
          clearCart={clearCart}
        />
      ) : (
        <div>
          <h1>ERROR LAYOUT</h1>
        </div>
      )}
    </div>
  );
};

export default Product;
