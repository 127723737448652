import React, { useState, useEffect, useContext } from "react";
import HeaderAlt from "../../components/layout/HeaderAlt";
import { AuthContext } from "../../context/auth";
import { Link } from "react-router-dom";
import axios, { axiosPrivate } from "../../api/axios";
import * as Loader from "react-spinners";
import SideCart from "../../components/layout/SideCart"
import { CartContext } from "../../context/cart";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const Wishlist = () => {
  const { userData, removeFromWishlist } = useContext(AuthContext);
  const {sideCartOpen, setSideCartOpen} = useContext(CartContext)
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getProductsInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get("/user/wishlist/123");
      if (response.status === 200) {
        setProducts(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductsInfo();
  }, []);

  return (
    <>
      <HeaderAlt backgroundColor="white"/>
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      <div className="pt-[10vh] flex-1  h-screen items-center flex flex-col">
        <h1 className="py-4 mb-4 border-b border-t border-gray-200 text-2xl">
          Mes Articles Enregistrés ({userData.wishlist.length})
        </h1>
        {userData.wishlist.length === 0 ? (
          <div className="w-full flex-1 max-w-2xl flex flex-col items-center">
            <img
              src={require("../../assets/images/manquant.png")}
              className="w-1/4"
            />
            <p className="w-2/3 self-center text-center text-gray-500">
              Il semblerait que vous n'ayez rien sauvegardé jusqu'à présent.
              Allez jeter un oeil à notre catalogue !
            </p>
            <Link
              className="px-3 py-2 mt-3 rounded-sm shadow-md bg-black hover:scale-[103%] duration-300"
              to="/catalog"
            >
              <h1 className="text-white">Explorer</h1>
            </Link>
          </div>
        ) : (
          <div>
            {isLoading ? (
             <div className="w-full h-full flex justify-center pt-5">
             <Loader.PuffLoader
                   color={"#000"}
                   loading={true}
                   cssOverride={override}
                   size={25}
                   aria-label="Loading Spinner"
                   data-testid="loader"
                   className="mt-5"
                 />
           </div>
            ) : (
              <div className="w-full flex flex-wrap items-center lg:px-8 justify-center">
                    {products.map((product) => (
                      <div key={product._id} className="w-[80%] lg:w-[30%] mt-3">
                          <Link to={`/products/${product._id}`}>
                            <img src={product.assets.mainImage} className="w-full aspect-1" alt="" srcset="" />
                          </Link>
                          <div className="flex items-center justify-between p-2">
                          <h1 className="text-xl">{product.name}</h1>
                          <h3>{product.price}€</h3>
                          </div>
                      </div>
                    ))}
                </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Wishlist;
