import React from "react";
import Stepper from "../../components/cart/Stepper";

const Legal = () => {
  return (
    <div className="flex flex-1 items-center justify-center p-12">
      <div className="flex flex-col space-y-3 w-full items-center">
      <h1>Adresse Mail</h1>
      <div className="w-1/4 h-12 border border-gray-300 rounded-md"></div>
      <button className="p-3 bg-black rounded-sm w-32">
        <p className="text-white">Suivant</p>
      </button>
      </div>

      <div className="flex flex-col space-y-3 w-full items-center">
      <h1>Username</h1>
      <div className="w-1/4 h-12 border border-gray-300 rounded-md"></div>
      <button className="p-3 bg-black rounded-sm w-32">
        <p className="text-white">Suivant</p>
      </button>
      </div>
    </div>
  );
};

export default Legal;
