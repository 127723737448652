import React from "react";
import { BsInstagram, BsLinkedin, BsTiktok } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="w-full border-t border-black flex flex-col items-center justify-end mb-5 mt-10">
        <div className="w-full max-w-[75%] flex items-center justify-between">
           <div className="w-2/3 hidden lg:inline-flex flex-col justify-center h-64 pr-20">
            <h2 className="font-bold">Vers une mode upcyclée, unique et responsable.
            </h2>
            <div className="flex space-x-2 mt-2">
            <BsInstagram className="text-xl hover:cursor-pointer"/>
            <BsTiktok className="text-xl hover:cursor-pointer"/>
            <BsLinkedin className="text-xl hover:cursor-pointer"/>
            </div>
           </div>
            <div className="flex-1 flex flex-col space-y-8 font-main lg:pl-12 items-center lg:items-start py-12 lg:py-0">
                <h2 className="text-xl hover:cursor-pointer">CONTACT</h2>
                <div className="flex flex-col items-center lg:items-start">
                <p className="hover:cursor-pointer">Suivre Votre Commande</p>
                    <p className="hover:cursor-pointer">Mentions Légales</p>
                    <p className="hover:cursor-pointer">Politique de retours</p>
                </div>
            </div>
        </div>
      <div className="flex flex-col w-full items-center mt-8">
      <h3 className="font-main text-sm">© No Context - Copyright 2024</h3>
        <div className="px-2 py-2 rounded-lg bg-black mt-2 shadow-lg">
        <p className="text-[8px] text-white font-bold">made with 🤍 by sam</p>
        </div>
        
        <img
          src={require("../../assets/images/shoe-bottom-panel.jpg")}
          className="w-2/3 lg:w-1/4"
          alt="lol"
        />
      </div>
    </div>
  );
};

export default Footer;
