import React, { useState, useEffect } from "react";
import HeaderAlt from "../../components/layout/HeaderAlt";
import Footer from "../../components/layout/Footer";
import { useSpring, animated, useTransition } from "react-spring";
import NewFooter from "../../components/layout/NewFooter";

const CGV = () => {
  const [flip, setFlip] = useState(false);
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    immediate: false,
    reset: flip,
    delay: 200,
    // onRest: () => setFlip(prev => !prev)
  });

  const [visible, setVisible] = useState(false);

  const data = [{text: "zebi", id: 1234}, {text: "carcassione", id: 56354}];
  const [index, setIndex] = useState(0);

  // const testAnimation = useTransition(data[index], item => item.id, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  //   config: { tension: 220, friction: 120 },
  // });

  const testAnimation = useTransition(data[index], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 120 },
  });

  return (
    <>
      <HeaderAlt backgroundColor="white" />
      <div className="pt-[10vh] flex-1  h-screen items-center flex flex-col">
        <h1 className="py-4 mb-4 border-b border-t border-gray-200 font-bold text-2xl">
          Conditions Générales de Vente
        </h1>
        

        <div className="bg-white text-gray-700 p-5">
      {/* <h1 className="text-xl font-bold">Conditions Générales de Vente</h1> */}

      <h2 className="mt-4 font-semibold">1. Objet</h2>
      <p>
        Les présentes Conditions Générales de Vente (ci-après dénommées les "CGV") régissent les modalités selon lesquelles
        "No Context" (ci-après dénommée "la Marketplace") propose à la vente des pièces de vêtements upcyclés, y compris des
        articles contenant des marques, aux utilisateurs de la plateforme.
      </p>

      <h2 className="mt-4 font-semibold">2. Acceptation des Conditions</h2>
      <p>
        En accédant à la Marketplace et en effectuant un achat, l'utilisateur reconnaît avoir pris connaissance et accepté sans
        réserve les présentes CGV. Tout achat effectué sur la Marketplace est soumis aux présentes CGV.
      </p>

      <h2 className="mt-4 font-semibold">3. Produits</h2>
      <p>
        La Marketplace "No Context" propose à la vente des pièces de vêtements upcyclés, qui peuvent inclure des articles portant
        des marques de tiers. Les produits sont décrits et présentés avec la plus grande exactitude possible. Cependant, des
        variations peuvent survenir en raison de la nature upcyclée des produits. La Marketplace ne peut être tenue responsable des
        différences mineures entre les produits présentés sur le site et les produits livrés.
      </p>

      <h2 className="mt-4 font-semibold">4. Commandes</h2>
      <p>
        L'utilisateur peut passer commande des produits disponibles sur la Marketplace selon les modalités prévues sur le site.
        Toute commande vaut acceptation des prix et descriptions des produits disponibles à la vente. La Marketplace se réserve
        le droit de refuser ou d'annuler toute commande en cas de problème de paiement, de rupture de stock, ou pour tout autre
        motif légitime. En cas d'annulation de commande, l'utilisateur sera informé et remboursé du montant de sa commande.
      </p>

      <h2 className="mt-4 font-semibold">5. Prix et Paiement</h2>
      <p>
        Les prix des produits sont indiqués en euros et sont susceptibles d'être modifiés à tout moment. Le paiement s'effectue
        en ligne par les moyens de paiement acceptés sur la plateforme. La commande est considérée comme valide et traitée une fois
        le paiement confirmé. La Marketplace se réserve le droit de suspendre ou d'annuler toute commande en cas de suspicion de
        fraude ou d'activité illégale.
      </p>

      <h2 className="mt-4 font-semibold">6. Livraison</h2>
      <p>
        Les délais et modalités de livraison sont précisés lors du processus de commande. La Marketplace s'engage à livrer les produits
        dans les meilleurs délais, mais ne peut être tenue responsable des retards imputables aux transporteurs. Les frais de livraison
        sont à la charge de l'utilisateur et varient en fonction du lieu de livraison et du mode de transport choisi. En cas de retard
        de livraison important, l'utilisateur peut contacter le service client de la Marketplace pour obtenir des informations sur le
        suivi de sa commande.
      </p>

      <h2 className="mt-4 font-semibold">7. Droit de Rétractation</h2>
      <p>
        Conformément à la législation en vigueur, l'utilisateur dispose d'un délai de rétractation de 14 jours à compter de la réception
        des produits pour exercer son droit de rétractation, sans avoir à justifier de motifs ni à payer de pénalités. Les frais de retour
        sont à la charge de l'utilisateur. La Marketplace s'engage à rembourser l'utilisateur dans les meilleurs délais et au plus tard
        dans les 14 jours suivant la réception des produits retournés.
      </p>

      <h2 className="mt-4 font-semibold">8. Responsabilité</h2>
      <p>
        La responsabilité de la Marketplace ne saurait être engagée en cas de dommages indirects résultant de l'utilisation des produits
        vendus sur la plateforme. La Marketplace ne garantit pas l'exactitude des informations fournies par les vendeurs tiers concernant
        les produits contenant des marques. La responsabilité de la Marketplace est limitée au montant de la commande passée par l'utilisateur.
      </p>

      <h2 className="mt-4 font-semibold">9. Propriété Intellectuelle</h2>
      <p>
        Les marques de tiers utilisées sur les produits demeurent la propriété exclusive de leurs titulaires respectifs. Aucun droit de
        propriété intellectuelle n'est cédé à l'utilisateur au titre de l'achat de produits contenant des marques. La Marketplace s'engage
        à retirer tout produit contrefait signalé par les titulaires des droits de propriété intellectuelle.
      </p>

      <h2 className="mt-4 font-semibold">10. Données Personnelles</h2>
      <p>
        Les données personnelles collectées dans le cadre des commandes sont traitées conformément à la Politique de Confidentialité disponible
        sur la Marketplace. La Marketplace s'engage à protéger les données personnelles de ses utilisateurs et à ne les utiliser que dans le cadre
        de l'exécution des commandes et de l'amélioration des services proposés.
      </p>

      <h2 className="mt-4 font-semibold">11. Litiges et Droit Applicable</h2>
      <p>
        Tout litige relatif aux présentes CGV sera soumis au droit français. En cas de litige, les parties s'efforceront de trouver une solution à
        l'amiable avant toute action judiciaire. En cas de litige, les tribunaux compétents seront ceux du ressort du siège social de la Marketplace.
        Les utilisateurs et les vendeurs s'engagent à soumettre tout différend relatif à une transaction sur la Marketplace à un processus de médiation
        avant d'entamer toute action judiciaire.
      </p>

      <h2 className="mt-4 font-semibold">12. Modalités d'Utilisation pour les Vendeurs</h2>
      <p>
        Les vendeurs s'engagent à fournir des informations exactes et complètes sur les produits qu'ils mettent en vente sur la Marketplace. Ils
        s'engagent également à respecter les délais de livraison indiqués et à assurer la qualité des produits proposés à la vente. En cas de litige
        avec un acheteur, les vendeurs s'engagent à coopérer avec la Marketplace pour résoudre le différend de manière équitable et conforme aux
        présentes CGV.
      </p>

      <h2 className="mt-4 font-semibold">13. Modification des CGV</h2>
      <p>
        La Marketplace se réserve le droit de modifier les présentes CGV à tout moment. Les CGV applicables sont celles en vigueur au moment de la
        passation de la commande. Les utilisateurs seront informés de toute modification des CGV par tout moyen approprié et seront invités à les
        accepter à nouveau lors de leur prochaine commande.
      </p>

      <p className="mt-4 font-light text-sm">Fait à Lyon, le 03 Juillet 2024</p>
    </div>
        <NewFooter />
      </div>
    </>
  );
};

export default CGV;
