import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import HeaderAlt from "../../components/layout/HeaderAlt";
import { FaEnvelope, FaStore, FaTshirt, FaPuzzlePiece, FaPaintBrush, FaPen, FaImage } from "react-icons/fa";

const BecomeSeller = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    businessName: "",
    upcyclingType: "",
    bio: "",
  });
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [touched, setTouched] = useState({});
  const [generalError, setGeneralError] = useState("");

  const onDrop = (acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles].slice(0, 5));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 5,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));
  };

  const handleUpcyclingTypeSelect = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      upcyclingType: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      upcyclingType: true,
    }));
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const validateStep = (currentStep) => {
    const newErrors = {};
    let isValid = true;
    switch (currentStep) {
      case 1:
        if (!formData.email) {
          newErrors.email = "L'adresse e-mail est requise";
          isValid = false;
        } else if (!/^\S+@\S+$/i.test(formData.email)) {
          newErrors.email = "Adresse e-mail invalide";
          isValid = false;
        }
        break;
      case 2:
        if (!formData.businessName) {
          newErrors.businessName = "Le nom de l'entreprise est requis";
          isValid = false;
        }
        if (!formData.upcyclingType) {
          newErrors.upcyclingType = "Le type d'upcycling est requis";
          isValid = false;
        }
        break;
      case 3:
        if (!formData.bio) {
          newErrors.bio = "La biographie est requise";
          isValid = false;
        } else if (formData.bio.length < 20) {
          newErrors.bio = "La biographie doit contenir au moins 20 caractères";
          isValid = false;
        } else if (formData.bio.length > 500) {
          newErrors.bio = "La biographie ne doit pas dépasser 500 caractères";
          isValid = false;
        }
        break;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleNextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
      setGeneralError("");
    } else {
      setGeneralError("Tous les champs sont obligatoires. Veuillez remplir tous les champs avant de continuer.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep(step)) {
      setIsSubmitting(true);
      try {
        // Simulating API call
        await new Promise(resolve => setTimeout(resolve, 2000));
        console.log(formData, images);
        setSubmitSuccess(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const upcyclingTypes = [
    { value: "reconstruction", label: "Reconstruction de vêtements", description: "Transformez des vêtements existants en pièces uniques et modernes.", icon: FaTshirt },
    { value: "patchwork", label: "Patchwork et quilting", description: "Créez des pièces originales en assemblant différents tissus de manière artistique.", icon: FaPuzzlePiece },
    { value: "broderie", label: "Broderie et décoration", description: "Sublimez des vêtements par la broderie artisanale et des embellissements créatifs.", icon: FaPaintBrush },
  ];

  const ErrorMessage = ({ error }) => (
    <p className="text-red-500 bg-red-100 border border-red-400 rounded-md p-2 mt-1">{error}</p>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Étape 1: Adresse e-mail</h2>
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={() => setTouched({ ...touched, email: true })}
                placeholder="Votre adresse e-mail"
                className="w-full pl-10 pr-3 py-2 border rounded-md"
              />
            </div>
            {touched.email && errors.email && <ErrorMessage error={errors.email} />}
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Étape 2: Informations sur l'entreprise</h2>
            <div className="relative">
              <FaStore className="absolute top-3 left-3 text-gray-400" />
              <input
                name="businessName"
                type="text"
                value={formData.businessName}
                onChange={handleInputChange}
                onBlur={() => setTouched({ ...touched, businessName: true })}
                placeholder="Nom de votre entreprise"
                className="w-full pl-10 pr-3 py-2 border rounded-md"
              />
            </div>
            {touched.businessName && errors.businessName && <ErrorMessage error={errors.businessName} />}
            <h3 className="text-xl font-semibold mt-4">Type d'upcycling</h3>
            <p className="text-sm text-gray-600 mb-2">Sélectionnez votre spécialité</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {upcyclingTypes.map((type) => (
                <div
                  key={type.value}
                  className={`p-4 border rounded-md cursor-pointer transition-all duration-200 ${
                    formData.upcyclingType === type.value
                      ? "border-black bg-black text-white"
                      : "hover:border-black"
                  }`}
                  onClick={() => handleUpcyclingTypeSelect(type.value)}
                >
                  <type.icon className="text-3xl mb-2" />
                  <h3 className="font-bold">{type.label}</h3>
                  <p className="text-sm">{type.description}</p>
                </div>
              ))}
            </div>
            {touched.upcyclingType && errors.upcyclingType && <ErrorMessage error={errors.upcyclingType} />}
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Étape 3: À Propos de Vous</h2>
            <div className="relative">
              <FaPen className="absolute top-3 left-3 text-gray-400" />
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
                onBlur={() => setTouched({ ...touched, bio: true })}
                placeholder="Votre biographie (20-500 caractères)"
                className="w-full pl-10 pr-3 py-2 border rounded-md h-32"
              />
              <p className="text-sm text-gray-500 mt-1">
                {formData.bio.length} / 500 caractères
              </p>
            </div>
            {touched.bio && errors.bio && <ErrorMessage error={errors.bio} />}
            <h3 className="text-xl font-semibold mt-4">Images de vos créations</h3>
            <p className="text-sm text-gray-600 mb-2">Ajoutez jusqu'à 5 photos de vos meilleures pièces</p>
            <div {...getRootProps()} className="border-2 border-dashed border-gray-300 rounded-md p-4 text-center cursor-pointer">
              <input {...getInputProps()} />
              <FaImage className="mx-auto text-4xl text-gray-400 mb-2" />
              <p>Glissez-déposez jusqu'à 5 images ici, ou cliquez pour sélectionner</p>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
              {images.map((file, index) => (
                <div key={index} className="relative">
                  <img src={URL.createObjectURL(file)} alt={`Upload ${index + 1}`} className="w-full h-20 object-cover rounded-md" />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-black text-white rounded-full w-6 h-6 flex items-center justify-center text-sm hover:bg-gray-800"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  if (submitSuccess) {
    return (
      <div className="relative overflow-hidden bg-gray-100 min-h-screen">
        <HeaderAlt />
        <div className="container mx-auto px-4 pb-8 pt-32 text-center">
          <h1 className="text-3xl font-bold mb-4">Demande envoyée avec succès!</h1>
          <p className="text-xl">Merci pour votre candidature. Nous vous contacterons bientôt.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden bg-gray-100 min-h-screen">
      <HeaderAlt />
      <div className="container mx-auto px-4 pt-16 pb-8">
        <h1 className="text-3xl font-bold mb-8 text-center">Devenir vendeur</h1>
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-8">
          {generalError && <ErrorMessage error={generalError} />}
          {renderStep()}
          <div className="mt-8 flex justify-between">
            {step > 1 && (
              <button
                type="button"
                onClick={() => {
                  setStep(step - 1);
                  setGeneralError("");
                }}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
              >
                Précédent
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                onClick={handleNextStep}
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition-colors ml-auto"
              >
                Suivant
              </button>
            ) : (
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 transition-colors ml-auto disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {isSubmitting ? "Envoi en cours..." : "Faire la demande"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BecomeSeller;