import React, { useContext, useState } from 'react';
import axios from 'axios';
import HeaderAlt from './layout/HeaderAlt';
import NewFooter from './layout/NewFooter';
import { CartContext } from '../context/cart';
import SideCart from './layout/SideCart';

const ArticleThree = () => {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: '',
  });
  const {sideCartOpen, setSideCartOpen} = useContext(CartContext)
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSuccess(false);

    // Clear previous errors
    const newErrors = {};

    // Validate email
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // If there are errors, update state and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('/api/v1/test/contact', formData);

      if (response.status === 200) {
        setIsSuccess(true);
        setFormData({ email: '', subject: '', message: '' });
        setErrors({});
      } else {
        alert('There was an issue sending your message. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error sending your message. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='relative pt-[5vh] lg:pt-0'>
      <HeaderAlt backgroundColor='white'/>
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      <section className="bg-white">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">
          Nous contacter
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
          Vous avez un problème? Besoin d'informations? N'hésitez pas à nous adresser un message.
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              Votre mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`shadow-sm bg-gray-50 border ${errors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
              placeholder="contact@no-context.fr"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900">
              Sujet
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
              placeholder="Sujet de la demande"
              required
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">
              Votre message
            </label>
            <textarea
              id="message"
              name="message"
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Dites-nous comment nous pouvons vous aider..."
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          {Object.keys(errors).length > 0 && (
            <div className="text-sm text-red-600 text-center mb-4">
              {Object.values(errors).map((error, index) => (
                <p key={index} className="mt-2">
                  {error}
                </p>
              ))}
            </div>
          )}
          <div className="flex justify-center">
            <button
              type="submit"
              className="py-4 px-8 text-sm font-medium text-center text-white rounded-lg bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-500"
            >
              {isLoading ? 'En cours...' : 'Envoyer'}
            </button>
          </div>
        </form>
        {isSuccess && (
          <div className="mt-4 p-4 text-sm text-center text-green-700 bg-green-100 rounded-lg">
            Votre message a bien été envoyé !
          </div>
        )}
      </div>
    </section>
    {/* FAQ SECTION */}
    <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">Questions posées souvent</h2>
                <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                </svg>
                                Est-il possible de faire un retour des articles?
                            </h3>
                            <p className="text-gray-500">Oui, pour la majorité des produits de notre catalogue il est possible de faire un retour gratuit sous 14 jours après date de réception. Attention cependant, certaines créations sont faites sur-mesure et d'autres modalités de retour peuvent s'appliquer, se référer à la page produit.</p>
                        </div>
                        {/* Additional FAQs can be mapped similarly */}
                    </div>
                    <div>
                        {/* This part should be populated similarly with other FAQ items */}
                    </div>
                </div>
            </div>
        </section>

        <NewFooter />
    </div>
  );
};

export default ArticleThree;
