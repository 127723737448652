import React, {useContext} from "react";
import FooterTwo from "./layout/FooterTwo";
import { Link } from "react-router-dom";
import HeaderAlt from "./layout/HeaderAlt";
import SideCart from "./layout/SideCart";
import { CartContext } from "../context/cart";
import NewFooter from "./layout/NewFooter";
import { LiaTshirtSolid } from "react-icons/lia";
import { PiPants } from "react-icons/pi";
import { TbJacket } from "react-icons/tb";
import { GiBedLamp, GiConverseShoe, GiLargeDress, GiPearlNecklace, GiShoppingBag } from "react-icons/gi";




const HomeTest = () => {
    const { sideCartOpen, setSideCartOpen } = useContext(CartContext);
  return (
    <div className="relative overflow-hidden">
        <HeaderAlt /> 
        <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      {/* HERO */}
      <div
        className="h-[100vh] lg:h-full w-full"
        //   ref={sectionRef}
      >
        <div className="h-[100vh] lg:h-full flex flex-row">
          <div className="flex-1 hidden lg:inline-flex justify-center items-start">
            <img
              src={
                "https://ncproducts.s3.eu-west-3.amazonaws.com/hero-part-1.jpg"
              }
              className="w-[100%] h-full text-lg asp"
              alt="lol"
            />
          </div>

          <div className="flex-1 flex items-center justify-center relative">
            <div className="absolute top-0 mt-3 inline-flex lg:hidden flex-col w-full items-center justify-center">
              {/* <img
                src={"https://ncproducts.s3.eu-west-3.amazonaws.com/hero-part-2.jpg"}
                className="w-[10%] object-cover overflow-hidden"
                alt="lol"
              /> */}
              <h1 className=" font-main text-2xl mt-12 ">no context</h1>
            </div>
            <img
              src={require("../assets/images/hero-part-2.jpg")}
              className="h-[100%] w-[100%] object-cover overflow-hidden"
              alt="lol"
            />
          </div>
        </div>
      </div>
      {/* TEST SECTION */}
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md my-4 lg:my-8">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Vers une nouvelle façon de consommer
            </h2>
            <p className="text-gray-500 sm:text-xl">
              Stop à la surconsommation et au surgâchis, les articles upcyclés
              proviennent de matériaux déjà existants ce qui réduit
              considérablement leur empreinte carbone.
            </p>
          </div>
        </div>
      </section>
      {/* https://ncproducts.s3.eu-west-3.amazonaws.com/ */}
      <video
          width="100%"
          type="video/mp4"
          src={"https://ncproducts.s3.eu-west-3.amazonaws.com/bal-video-done.mov"}
          autoPlay
          playsInline
          muted
          loop
        ></video>
      {/* TEST SECTION 2 */}
      <section className="bg-white w-full">
        <div className="py-8 px-4 mx-auto w-full max-w-screen-xl sm:py-16 lg:px-6">
          <div className=" my-4 lg:my-8 flex flex-col items-end w-full">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Des pièces uniques.. pour tous.
            </h2>
            <p className="text-gray-500 sm:text-xl">
              Chaque article est issu de l'artisanat et est fait sur-mesure ce
              qui le rends unique par nature et par design.
            </p>
          </div>
        </div>
      </section>
      <video
          width="100%"
          className="mb-4"
          type="video/mp4"
          src={"https://ncproducts.s3.eu-west-3.amazonaws.com/hbx-video-done.mov"}
          autoPlay
          playsInline
          loop
          muted
        ></video>
      {/* CATEGORIES */}
      <section className="bg-gray-50 py-8 antialiased md:py-16">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className="mb-4 flex items-center justify-between gap-4 md:mb-8">
            <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Explorez par catégorie
            </h2>
            <a
              href="/catalog"
              title=""
              className="flex items-center text-base font-medium text-primary-700 hover:underline"
            >
              Voir tout
              <svg
                className="ms-1 h-5 w-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </a>
          </div>
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Hauts"
              className="flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <LiaTshirtSolid className="mr-1"/>

              <span className="text-sm font-medium text-gray-900">
                Hauts
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Pantalons"
              className="flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <PiPants className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Pantalons
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Vestes"
              className="flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <TbJacket className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Vestes
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Chaussures"
              className="flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <GiConverseShoe className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Chaussures
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Accessoires"
              className="flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <GiBedLamp className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Accessoires
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Accessoires"
              className="hidden lg:inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <GiShoppingBag className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Sacs
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Hauts"
              className="hidden lg:inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <GiLargeDress className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Robes
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
            {/* Repeat the following block for each category link */}
            <a
              href="/catalog?target=Accessoires"
              className="hidden lg:inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
            >
              <GiPearlNecklace className="mr-1" />

              <span className="text-sm font-medium text-gray-900">
                Bijoux
              </span>
            </a>
            {/* Additional category links would follow the same pattern */}
          </div>
        </div>
      </section>

      {/* SNEAKERS SELECTION */}
      <h2 className="text-xs tracking-widest font-bold self-start ml-3 lg:ml-28 mt-12">
        TENDANCE
      </h2>
      <h1 className="text-4xl font-main self-start ml-3 lg:ml-28 mt-2">
        Les Nouvelles Pépites
      </h1>
      <div className="w-full px-0 lg:px-10 flex flex-col lg:flex-row mt-8 space-x-0 lg:space-x-4 items-center justify-between">
        <Link
          to="/catalog"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          //   onClick={() => setSideCartOpen(true)}
        >
          <img
            src={require("../assets/images/cyclered.jpeg")}
            className="w-[100%] lg:h-[26rem] object-cover"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Jean Réversible WeCyrcled
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            339.99€
          </h2>
        </Link>
        <Link
          to="/catalog"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          //   onClick={() => setSideCartOpen(true)}
        >
          <img
            src={"https://ncproducts.s3.eu-west-3.amazonaws.com/upzshirt1.jpg"}
            className="w-[100%] lg:h-[26rem] object-cover"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Chemise Patchwork Upznshit
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            89.99€
          </h2>
        </Link>
        <Link
          to="/catalog"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          //   onClick={() => setSideCartOpen(true)}
        >
          <img
            src={"https://ncproducts.s3.eu-west-3.amazonaws.com/hoodietap1.jpeg"}
            className="w-[100%] lg:h-[26rem] object-cover"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Hoodie Tapisserie Stussy
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            199.99€
          </h2>
        </Link>
        <Link
          to="/catalog"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          //   onClick={() => setSideCartOpen(true)}
        >
          <img
            src={"https://ncproducts.s3.eu-west-3.amazonaws.com/upzshirt2.jpg"}
            className="w-[100%] lg:h-[26rem] object-cover"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Chemise Patchwork Upznshit
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            79.99€
          </h2>
        </Link>
      </div>

      {/* THE WHEEL SECTION */}
      <section className="bg-white">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Notre Vision
            </h2>
            <p className="mb-4">
            Les vêtements upcyclés sont plus qu'une tendance : ils sont un choix durable pour un avenir meilleur. Chaque vêtement upcyclé sur No Context réduit la demande de nouvelle production textile, conserve les ressources et minimise les déchets en décharge. L'industrie de la mode est l'un des plus grands pollueurs au monde, mais en choisissant des vêtements upcyclés, vous contribuez à atténuer son impact environnemental. 
            </p>
            <p>
            Vous n'améliorez pas seulement votre garde-robe avec des pièces uniques, mais vous avez aussi un impact positif sur l'environnement.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src="https://ncproducts.s3.eu-west-3.amazonaws.com/wheel-2.jpeg"
              alt="office content 1"
            />
            <img
              className="mt-4 w-full lg:mt-10 rounded-lg"
              src="https://ncproducts.s3.eu-west-3.amazonaws.com/wheel-1.jpeg"
              alt="office content 2"
            />
          </div>
        </div>
      </section>
{/* ONE SECTION KNITWEAR */}
<div className="w-full overflow-x-auto lg:h-[100vh]  flex flex-col lg:flex-row mt-8 no-scrollbar">
        <div className="w-full lg:h-[80vh] flex flex-col lg:flex-row">
          <div className="flex-1 flex items-end lg:justify-center">
            <img
              src={require("../assets/images/knitwear-guy.jpeg")}
              className="w-[70%]"
              alt="lol"
            />
          </div>
          <div className="flex-1 flex flex-col justify-between items-center my-2 lg:my-0">
            <h1 className="hidden lg:inline-block font-bold text-xs tracking-widest">
              UPCYCLÉ
            </h1>
            <div className="flex flex-col items-center">
              <h1 className="text-7xl font-main ">Knitwear</h1>
              <h1 className="text-7xl font-main ">Vestes</h1>
              <h1 className="text-7xl font-main ">Tops</h1>
              <h1 className="text-7xl font-main ">Robes</h1>
              <h1 className="text-7xl font-main ">Sneaker</h1>
              <h1 className="text-7xl font-main ">Manteaux</h1>
            </div>
            <h2 className="hidden lg:inline-block font-bold text-xs text-slate-600 tracking-widest">
              CUSTOM
            </h2>
          </div>
          <div className="flex-1 flex justify-end lg:justify-center items-start">
            <img
              src={require("../assets/images/knitwear-girl.jpeg")}
              className="w-[60%]"
              alt="lol"
            />
          </div>
        </div>
        {/* <div className="w-36 h-full flex">
          <div className="flex-1 flex items-end lg:justify-center">
            <img
              src={require("../assets/images/knitwear-guy.jpeg")}
              className="w-[70%]"
              alt="lol"
            />
          </div>
          <div className="flex-1 flex flex-col justify-between items-center my-2 lg:my-0">
            <h1 className="hidden lg:inline-block font-bold text-xs tracking-widest">
              UPCYCLÉ
            </h1>
            <div className="flex flex-col items-center">
              <h1 className="text-7xl font-main ">Knitwear</h1>
              <h1 className="text-7xl font-main ">Vestes</h1>
              <h1 className="text-7xl font-main ">Tops</h1>
              <h1 className="text-7xl font-main ">Robes</h1>
              <h1 className="text-7xl font-main ">Sneaker</h1>
              <h1 className="text-7xl font-main ">Manteaux</h1>
            </div>
            <h2 className="hidden lg:inline-block font-bold text-xs text-slate-600 tracking-widest">
              CUSTOM
            </h2>
          </div>
          <div className="flex-1 flex justify-end lg:justify-center items-start">
            <img
              src={require("../assets/images/knitwear-girl.jpeg")}
              className="w-[60%]"
              alt="lol"
            />
          </div>
        </div> */}
      </div>
      {/* JACKETS */}
      <div className="flex justify-center mt-12 lg:mt-0">
        <h1 className="text-4xl tracking-tight self-center ">
          Les Bombers Upcyclés
        </h1>
      </div>
      <div className=" w-full my-12 flex flex-col lg:flex-row">
        <Link className="flex-1 hover:cursor-pointer"
        to="/catalog">
          <img
            src={require("../assets/images/kamad-1.png")}
            className="w-[100%]"
            alt="lol"
          />
        </Link>
        <Link className="flex-1 hover:cursor-pointer" to="/catalog">
          <img
            src={require("../assets/images/kamad-3.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </Link>
        <Link className="flex-1 hover:cursor-pointer" to="/catalog">
          <img
            src={require("../assets/images/kamad-2.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </Link>
        <Link className="flex-1 hover:cursor-pointer" to="/catalog">
          <img
            src={require("../assets/images/kamad-4.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </Link>
      </div>
      
      {/* CTA BUTTON/IMAGE */}
      <section className="bg-white">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img
            className="w-full"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg"
            alt="dashboard image"
          />
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Rejoignez dès maintenant notre communauté de créateurs.
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg">
              Exposez vos créations à des milliers d'utilisateurs, générez des
              revenus et profitez d'une expérience spécialement dédiée aux aléas
              de l'upcycling.
            </p>
            <a
              href="/become-seller"
              className="inline-flex items-center text-white bg-black focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:scale-105 duration-200"
            >
              Devenir vendeur
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <NewFooter />
    </div>
  );
};

export default HomeTest;