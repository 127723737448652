import React, { useEffect, useState, useContext } from "react";
import HeaderAlt from "../components/layout/HeaderAlt";
import { CartContext } from "../context/cart";
import SideCart from "../components/layout/SideCart";
import { AuthContext } from "../context/auth";
import { Fa42Group } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import orderConfirm from "../assets/lotties/order-success.json"
import orderFail from "../assets/lotties/order-fail.json";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const Checkout = () => {
  const [message, setMessage] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(false);
  const {
    clearCart,
    addToCart,
    removeFromCart,
    cartItems,
    sideCartOpen,
    setSideCartOpen,
  } = useContext(CartContext);
  const { isLog } = useContext(AuthContext);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setOrderSuccess(true);
      setMessage(
        `Votre commande est validée! Vous allez recevoir un email de confirmation. ${
          isLog
            ? "Vous pouvez aussi suivre votre commande depuis votre espace."
            : "Créez un compte pour suivre votre commande en direct."
        }`
      );
      clearCart();
    }
    if (query.get("canceled")) {
      setMessage(
        "Un problème est survenu lors du paiement. Vous n'avez pas été débité."
      );
    }
  }, []);

  return (
    <div className="pt-16">
      <HeaderAlt />
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      <div className="flex flex-col items-center w-full lg:w-1/2 border border-gray-200 mx-auto mt-10 rounded-sm shadow-sm px-3">
        {orderSuccess ? (
          <Lottie animationData={orderConfirm} className="w-40 mt-5" loop={true} />
        ) : (
          <Lottie animationData={orderFail} className="w-60 mt-5" loop={true} />
        )}
        <h1 className="font-bold text-xl mb-2 mt-5">{orderSuccess ? "Merci!" : "Erreur!"}</h1>
        <h1 className="text-center text-gray-600 text-sm">{message}</h1>
        <div className="w-2/3 flex items-center justify-center space-x-4 mt-7 mb-5">
          <Link
            to={orderSuccess ? isLog ? "/profil" : "/register" : "/cart"}
            className="flex items-center justify-center bg-black rounded-md shadow-sm p-3 w-full border-black border mx-1 mb-3 mt-2 hover:scale-105 duration-300"
            // onClick={handlePay}
            // disabled={isLoading}
          >
            <h1 className="text-white text-sm">
              {orderSuccess ? isLog ? "Mon Compte" : "Inscription" : "Réessayer"}
            </h1>
          </Link>
          <Link
            to="/"
            className="flex items-center justify-center bg-gray-100 rounded-md shadow-sm p-3 w-full border-gray-200 border mx-1 mb-3 mt-2 hover:scale-105 duration-300 "
            // onClick={() => setCartStep("delivery")}
            // disabled={isLoading}
          >
            <h1 className="text-black text-sm">Accueil</h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
