import React, { useRef, useEffect, useContext, useState } from "react";
import Header from "../components/layout/Header";
import MatteSection from "../components/home/MatteSection";
import Footer from "../components/layout/Footer";
import TitleSection from "../components/home/TitleSection";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ToySection from "../components/home/ToySection";
import { CartContext } from "../context/cart";
import { Link } from "react-router-dom";
import SideCart from "../components/layout/SideCart";
import HeaderAlt from "../components/layout/HeaderAlt";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "white", color: "black" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", alignItems: "center", justifyContent: "center", background: "black", width: "30px", height: "30px", transform: "translate(0, -70%)", color: "black"}}
      onClick={onClick}
    />
  );
}

const Home = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow />
  };

  const sectionRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const [sideCartOpen, setSideCartOpen] = useState(true)
  const { sideCartOpen, setSideCartOpen } = useContext(CartContext);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  // useEffect(() => {
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: sectionRef.current,
  //       start: "center",
  //       scrub: true,
  //       // markers: true,
  //     },
  //   });
  //   tl.to(
  //     "#hero-container",
  //     {
  //       color: "black",
  //       backgroundColor: "white",
  //       duration: 0.1,
  //     }
  //     //   "-=2"
  //   );
  // }, []);

  return (
    <div
      className="flex flex-col flex-1 relative overflow-hidden "
      id="hero-container"
    >
      <HeaderAlt />
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />

      {/* <div className="inline-flex lg:hidden w-full items-center justify-center">
        <img
          src={require("../assets/images/logo-empty.PNG")}
          className="w-[15%] mt-8"
          alt="lol"
        />
      </div> */}

      {/* <div className="py-8 lg:py-8 justify-center flex" ref={sectionRef}>
        {/* <h1 className="font-main text-[5.1em] leading-[6rem] lg:leading-[9rem] lg:text-8xl lg:w-[40%]">
          Quand élégance et conscience rhyment, la mode n'a pas besoin de contexte. Des créations sur-mesure, upcyclées, bienvenue sur..
        </h1> */}
      {/* </div> */}

      {/* HERO */}
      <div className="h-[100vh] lg:h-full w-full" ref={sectionRef}>
        <div className="h-[100vh] lg:h-full flex flex-row">
          <div className="flex-1 hidden lg:inline-flex justify-center items-start">
            <img
              src={
                "https://ncproducts.s3.eu-west-3.amazonaws.com/hero-part-1.jpg"
              }
              className="w-[100%] h-full text-lg asp"
              alt="lol"
            />
          </div>

          <div className="flex-1 flex items-center justify-center relative">
            <div className="absolute top-0 mt-3 inline-flex lg:hidden flex-col w-full items-center justify-center">
              {/* <img
                src={"https://ncproducts.s3.eu-west-3.amazonaws.com/hero-part-2.jpg"}
                className="w-[10%] object-cover overflow-hidden"
                alt="lol"
              /> */}
              <h1 className=" font-main text-2xl mt-12 ">no context</h1>
            </div>
            <img
              src={require("../assets/images/hero-part-2.jpg")}
              className="h-[100%] w-[100%] object-cover overflow-hidden"
              alt="lol"
            />
          </div>
        </div>
      </div>

      <h2 className="text-xs tracking-widest font-bold self-start ml-3 lg:ml-28 mt-12">
        TENDANCE
      </h2>
      <h1 className="text-4xl font-main self-start ml-3 lg:ml-28 mt-2 mb-10">
        Populaire en ce moment
      </h1>
      <Slider {...settings} className="">
        <div
          to="/products/65f1b1088ddd79b88dace33e"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
        
        >
          <img
            src={require("../assets/images/arcteryx-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Snow Boot Arc'Teryx
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            189.99€
          </h2>
        </div>
        <div
          to="/products/nike-purse-shoe"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer"
        >
          <img
            src={require("../assets/images/nike-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Nike Purse Shoe
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            299.99€
          </h2>
        </div>
        <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/salomon-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Salomon Garden Party
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            249.99€
          </h2>
        </div>
        <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/nb-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            New Balance x Rick Owens
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            399.99€
          </h2>
        </div>
        <div
          to="/products/65f1b1088ddd79b88dace33e"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          
        >
          <img
            src={require("../assets/images/arcteryx-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Snow Boot Arc'Teryx
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            189.99€
          </h2>
        </div>
        <div
          to="/products/nike-purse-shoe"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer"
        >
          <img
            src={require("../assets/images/nike-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Nike Purse Shoe
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            299.99€
          </h2>
        </div>
        <div className="ml-3 flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/salomon-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Salomon Garden Party
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            249.99€
          </h2>
        </div>
        <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/nb-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            New Balance x Rick Owens
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            399.99€
          </h2>
        </div>
      </Slider>

      {/* ONE SECTION KNITWEAR */}
      <div className="w-full overflow-x-auto lg:h-[100vh] lg:pt-[10vh] flex flex-col lg:flex-row my-8 no-scrollbar pt-20">
        <div className="w-full lg:h-[80vh] flex flex-col lg:flex-row">
          <div className="flex-1 flex items-end lg:justify-center">
            <img
              src={require("../assets/images/knitwear-guy.jpeg")}
              className="w-[70%]"
              alt="lol"
            />
          </div>
          <div className="flex-1 flex flex-col justify-between items-center my-2 lg:my-0">
            <h1 className="hidden lg:inline-block font-bold text-xs tracking-widest">
              UPCYCLÉ
            </h1>
            <div className="flex flex-col items-center">
              <h1 className="text-7xl font-main ">Knitwear</h1>
              <h1 className="text-7xl font-main ">Vestes</h1>
              <h1 className="text-7xl font-main ">Tops</h1>
              <h1 className="text-7xl font-main ">Robes</h1>
              <h1 className="text-7xl font-main ">Sneaker</h1>
              <h1 className="text-7xl font-main ">Manteaux</h1>
            </div>
            <h2 className="hidden lg:inline-block font-bold text-xs text-slate-600 tracking-widest">
              CUSTOM
            </h2>
          </div>
          <div className="flex-1 flex justify-end lg:justify-center items-start">
            <img
              src={require("../assets/images/knitwear-girl.jpeg")}
              className="w-[60%]"
              alt="lol"
            />
          </div>
        </div>
        {/* <div className="w-36 h-full flex">
          <div className="flex-1 flex items-end lg:justify-center">
            <img
              src={require("../assets/images/knitwear-guy.jpeg")}
              className="w-[70%]"
              alt="lol"
            />
          </div>
          <div className="flex-1 flex flex-col justify-between items-center my-2 lg:my-0">
            <h1 className="hidden lg:inline-block font-bold text-xs tracking-widest">
              UPCYCLÉ
            </h1>
            <div className="flex flex-col items-center">
              <h1 className="text-7xl font-main ">Knitwear</h1>
              <h1 className="text-7xl font-main ">Vestes</h1>
              <h1 className="text-7xl font-main ">Tops</h1>
              <h1 className="text-7xl font-main ">Robes</h1>
              <h1 className="text-7xl font-main ">Sneaker</h1>
              <h1 className="text-7xl font-main ">Manteaux</h1>
            </div>
            <h2 className="hidden lg:inline-block font-bold text-xs text-slate-600 tracking-widest">
              CUSTOM
            </h2>
          </div>
          <div className="flex-1 flex justify-end lg:justify-center items-start">
            <img
              src={require("../assets/images/knitwear-girl.jpeg")}
              className="w-[60%]"
              alt="lol"
            />
          </div>
        </div> */}
      </div>

      {/* SNEAKERS SELECTION */}
      <h2 className="text-xs tracking-widest font-bold self-start ml-3 lg:ml-28 mt-12">
        TENDANCE
      </h2>
      <h1 className="text-4xl font-main self-start ml-3 lg:ml-28 mt-2">
        Les Nouveaux Arrivages Sneakers
      </h1>
      <div className="w-full px-0 lg:px-10 flex flex-col lg:flex-row mt-8 space-x-0 lg:space-x-4 items-center justify-between">
        <Link
          to="/products/65f1b1088ddd79b88dace33e"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          onClick={() => setSideCartOpen(true)}
        >
          <img
            src={require("../assets/images/arcteryx-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Snow Boot Arc'Teryx
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            189.99€
          </h2>
        </Link>
        <div
          to="/products/nike-purse-shoe"
          className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer"
        >
          <img
            src={require("../assets/images/nike-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Nike Purse Shoe
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            299.99€
          </h2>
        </div>
        <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/salomon-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            Salomon Garden Party
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            249.99€
          </h2>
        </div>
        <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
          <img
            src={require("../assets/images/nb-shoe.jpg")}
            className="w-[100%]"
            alt="lol"
          />
          <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
            New Balance x Rick Owens
          </h1>
          <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
            399.99€
          </h2>
        </div>
      </div>

      {/* SEE ALL */}
      <Link
        to="/catalog"
        className="hover:cursor-pointer inline-block lg:hidden"
      >
        <h1 className="text-2xl font-main underline mt-10">Voir Tout</h1>
      </Link>

      {/* JACKETS */}
      <h1 className="text-4xl font-main self-center mt-20">
        Les Bombers Upcyclés
      </h1>
      <div className=" w-full mt-12 flex flex-col lg:flex-row">
        <div className="flex-1 hover:cursor-pointer">
          <img
            src={require("../assets/images/kamad-1.png")}
            className="w-[100%]"
            alt="lol"
          />
        </div>
        <div className="flex-1 hover:cursor-pointer">
          <img
            src={require("../assets/images/kamad-3.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </div>
        <div className="flex-1 hover:cursor-pointer">
          <img
            src={require("../assets/images/kamad-2.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </div>
        <div className="flex-1 hover:cursor-pointer">
          <img
            src={require("../assets/images/kamad-4.jpg")}
            className="w-[100%]"
            alt="lol"
          />
        </div>
      </div>

      {/* NO CONTEXT TEXT */}
      {/* <div className="h-[100vh] flex items-center justify-center w-full">
        <h1 className="font-main text-8xl lg:text-8xl">no context.</h1>
      </div> */}

      {/* ZEBI */}
      {/* <div className="h-[100vh] w-full" ref={sectionRef}>
        <div className="mt-[15vh] h-[85vh] px-24 flex flex-row">
          <div className="flex-1 flex justify-center items-start">
            <img
              src={require("../assets/images/hero-1.jpeg")}
              className="w-[95%] text-lg asp"
              alt="lol"
            />
          </div>

          <div className="flex-1 flex items-center justify-center">
            <img
              src={require("../assets/images/hero-3.jpeg")}
              className="w-[95%]"
              alt="lol"
            />
          </div>
        </div>
      </div> */}

      {/* <TitleSection /> */}
      {/* <MatteSection /> */}
      {/* <ToySection /> */}
      <Footer />
    </div>
  );
};

export default Home;
