import { Fragment, useContext, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  BookmarkIcon,
  UserCircleIcon,
  BellIcon,
  TruckIcon,
  CogIcon,
  InformationCircleIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { TiHeartOutline } from "react-icons/ti";
import { TbLogout2 } from "react-icons/tb";
import { MdLogin } from "react-icons/md";

import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { CgProfile } from "react-icons/cg";
import { CiUser, CiShoppingCart } from "react-icons/ci";
import { CartContext } from "../../context/cart";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import Modal from "./Modal";

const products = [
  {
    name: "Profil",
    description: "Gérez vos informations.",
    href: "/profil",
    icon: UserCircleIcon,
  },
  {
    name: "Wishlist",
    description: "Une collection de vos articles coup de coeur.",
    href: "/wishlist",
    icon: BookmarkIcon,
  },
  {
    name: "Commandes",
    description: "Un suivi de vos commandes en direct.",
    href: "/orders",
    icon: TruckIcon,
  },
  // {
  //   name: "Notifications",
  //   description: "Lorem ipsum dolor sit amet.",
  //   href: "notifications",
  //   icon: BellIcon,
  // },
  {
    name: "Mentions Légales",
    description: "A propos de notre fonctionnement.",
    href: "/cgv",
    icon: BuildingLibraryIcon,
  },
];

const catalogs = [
  {
    name: "Hauts",
    description: "Lorem ipsum dolor sit amet.",
    href: "/catalog?target=Hauts",
    icon: UserCircleIcon,
  },
  {
    name: "Pantalons",
    description: "Une collection de vos articles coup de coeur.",
    href: "/catalog?target=Pantalons",
    icon: BookmarkIcon,
  },
  {
    name: "Vestes",
    description: "Lorem ipsum dolor sit amet.",
    href: "/catalog?target=Vestes",
    icon: TruckIcon,
  },
  {
    name: "Chaussures",
    description: "Lorem ipsum dolor sit amet.",
    href: "/catalog?target=Chaussures",
    icon: BellIcon,
  },
  {
    name: "Accessoires",
    description: "Lorem ipsum dolor sit amet.",
    href: "/catalog?target=Accessoires",
    icon: BellIcon,
  },
];
// const callsToAction = [
//   { name: "Paramètres", href: "#", icon: CogIcon },
//   { name: "Aide", href: "#", icon: InformationCircleIcon },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderAlt({ backgroundColor = "transparent"}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const { setSideCartOpen, cartItems } = useContext(CartContext);
  const { isLog, logoutUser, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = async () => {
    setIsLoading(true)
    await logoutUser()
    setIsLoading(false)
    navigate("/")
  }

  const callsToAction = [
    {
      name: isLog ? "Déconnexion" : "Se Connecter",
      href: isLog ? "/profil" : "/login",
      icon: isLog ? TbLogout2 : MdLogin,
      id: 1
    },
    { name: "Aide", href: "#", icon: InformationCircleIcon, id: 2 },
  ];
//  <Modal open={modalOpen} onClose={() => setModalOpen(false)} setModalOpen={setModalOpen} title={"Déconnexion"} subTitle={"Etes-vous sûrs de vouloir vous déconnecter?"} confirmText={"Confirmer"}></Modal>
  return (
    <header
      className={`bg-${backgroundColor} w-screen fixed top-0 z-30 lg:px-20`}
    >
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} setModalOpen={setModalOpen} title={"Déconnexion"} subTitle={"Etes-vous sûrs de vouloir vous déconnecter?"} confirmText={"Confirmer"} confirmAction={handleLogout} isLoading={isLoading}></Modal>
      <nav
        className=" flex flex-1 items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <Popover.Group className="hidden lg:flex lg:w-[25%] lg:gap-x-12">
          {/* <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-main leading-6 text-gray-900">
              Créations
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-gray-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover> */}
          <Link
            to="/catalog"
            className="text-lg font-main leading-6 text-gray-900"
          >
            Créations
          </Link>

          <Link to="/about" className="text-lg font-main leading-6 text-gray-900">
            À Propos
          </Link>
          {/* <div to="/" className="text-md font-bold leading-6 text-gray-900 hover:cursor-pointer" onClick={() => {
            if(isLog) {
              logoutUser()
            } else {
              navigate("/login")
            }
          }}>
            <h1>{isLog ? "Deconnexion" : "Connexion" }</h1>
          </div> */}
        </Popover.Group>
        <div
          className={`${mobileMenuOpen ? "hidden" : "inline-flex"} lg:hidden`}
        >
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 z-30"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div
          className={`${
            mobileMenuOpen ? "hidden" : "inline-flex"
          } lg:flex-1 justify-center`}
        >
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">No Context</span>
            <img
              className="h-9 w-auto"
              src={require("../../assets/images/favicon-png.png")}
              alt=""
            />
          </Link>
        </div>
        <button
          to="/cart"
          onClick={() => setSideCartOpen(true)}
          className={`${
            mobileMenuOpen ? "hidden" : "inline-flex"
          } lg:hidden font-main text-lg hover:cursor-pointer relative hover:scale-[105%] duration-200`}
        >
          {cartItems.length > 0 ? (
            <div className="h-2.5 w-2.5 bg-red-600 rounded-full absolute top-0 right-0 flex items-center justify-center">
              <h1 className="font-bold text-white text-[8px]">
                {cartItems.length}
              </h1>
            </div>
          ) : null}
          <CiShoppingCart className="text-2xl focus:outline-none " />
        </button>
        <div className="hidden lg:flex lg:justify-end lg:w-[25%]">
          <button
            to="/cart"
            onClick={() => setSideCartOpen(true)}
            className="font-main text-lg hover:cursor-pointer relative mr-4 hover:scale-[105%] duration-200"
          >
            {cartItems.length > 0 ? (
              <div className="h-2.5 w-2.5 bg-red-600 rounded-full absolute top-0 right-0 flex items-center justify-center">
                <h1 className="font-bold text-white text-[8px]">
                  {cartItems.length}
                </h1>
              </div>
            ) : null}
            <CiShoppingCart className="text-2xl focus:outline-none " />
          </button>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              <CiUser className="text-2xl focus:outline-none hover:scale-[105%] duration-200" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -right-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-gray-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    item.id === 1 ? <button
                      key={item.name}
                      onClick={() => {
                        if(isLog) {
                          // ADD MODAL
                          setModalOpen(true)
                          // logoutUser()
                        } else {
                          navigate("/login")
                        }
                      }}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </button> : <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </nav>
      {/* MOBILE MENU */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className={`flex lg:hidden`}>
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 z-30"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {/* <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600"
                alt=""
              />
            </a> */}
            {/* <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}
          </div>
          <div className="mt-10 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {isLog ? (
                  <Link className="flex items-center" to="/profil">
                    <img
                      src={userData.info.profilePicture}
                      alt=""
                      className="w-7 h-7 object-cover rounded-full mr-2"
                    />
                    <p className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Profil
                    </p>
                  </Link>
                ) : null}
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Catalogue
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...catalogs].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                <a
                  href="/wishlist"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Wishlist
                </a>

                <a
                  href="/orders"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Suivi Commande
                </a>
                <a
                  href="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  A Propos
                </a>
                <a
                  href="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact
                </a>
              </div>
              <div className="py-6">
                <button className="flex items-center" onClick={() => {
                  if(isLog) {
                    // ADD MODAL
                    // setModalOpen(true)
                    logoutUser()
                  } else {
                    navigate("/login")
                  }
                }}>
                  {isLog ? (
                    <TbLogout2 className="w-5 h-5 mr-2" />
                  ) : (
                    <MdLogin className="w-5 h-5 mr-2" />
                  )}
                  <p
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {isLog ? `Déconnexion` : "Connexion/Inscription"}
                  </p>
                </button>
                <div className="flex items-center">
                  <HiOutlineBuildingLibrary className="w-5 h-5 mr-2" />
                  <a
                    href="/cgv"
                    className="-mx-3 block rounded-lg px-3 py-2 mt-1 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Mentions Légales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
