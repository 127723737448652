import React, { useContext, useState, useEffect } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { CartContext } from "../../context/cart";
import { FaHeart, FaLocationDot, FaRegHeart } from "react-icons/fa6";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Carousel from "./Carousel";
import { X } from "react-feather";
import { AuthContext } from "../../context/auth";
import NewFooter from "../layout/NewFooter";
import dayjs from "dayjs";
import "dayjs/locale/fr";

const sizes = [
  {
    category: "Hauts", 
    type: "Hauts (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Pantalons", 
    type: "Pantalons (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Vestes", 
    type: "Vestes (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Chaussures", 
    type: "Chaussures (EU)",
    options: ["40", "41", "42", "43", "44", "45", "46", "47"],
  },
  {
    category: "Accessoires", 
    type: "",
    options: ["", "", "", "", "", "", "", ""],

    category: "Sneakers", 
    type: "Chaussures (EU)",
    options: ["", "", "", "", "", "", "", ""],
  },
];

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const ProductLayoutTemp = ({
  productInfo,
  addToCart,
  cartItems,
  removeFromCart,
  clearCart,
}) => {
  //   const { addToCart, cartItems } = useContext(CartContext);
  const [selectedSize, setSelectedSize] = useState(
    productInfo.additionalInfo.size.availableSizes.length === 1
      ? productInfo.additionalInfo.size.availableSizes[0]
      : null
  );
  const { isLog, userData, addToWishlist, removeFromWishlist } = useContext(AuthContext);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [imageViewer, setImageViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const getProductInfo = async () => {
    // setIsLoading(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(
        `/products/info/${productInfo._id}`,
        headers
      );
      if (response.status == 200) {
        addToCart(response.data);
      }
    } catch (err) {
      console.log(err);
      // setError(true)
    } finally {
      // setIsLoading(false);
    }
  };

  const handleDirectBuy = () => {
    clearCart();
    setSizeError(false);
    if (selectedSize !== null) {
      addToCart(productInfo, { sizeValue: selectedSize });
      navigate("/cart");
    } else {
      setSizeError(true);
    }
  };

  const handleAddToWishlist = async (productId) => {
    
    setIsLoading(true)
    if (
      isLog &&
      userData?.wishlist?.filter((item) => item === productInfo._id).length === 0
    ) {
      const result = await addToWishlist(productId);
      console.log("first IF option")
      if (result === 200) {
        console.log("something")
        //refreshproductinfo
      }
    }
    if (
      isLog &&
      userData?.wishlist?.filter((item) => item === productInfo._id).length > 0
    ) {
      const result = await removeFromWishlist(productId);
      console.log("second IF option")
      if (result === 200) {
        //refreshproductinfo
      }
    }
    if (!isLog) {
      navigate("/login")
    }
    setIsLoading(false)
  };

  const getDeliveryDate = (item) => {
    var date = new Date();
    date.setDate(date.getDate() + item.delivery.deliveryTime);
    return date;
  };

  return (
    <div class="bg-white font-main">
      {imageViewer ? (
        <div className="fixed w-screen h-screen z-50 bg-gray-700 bg-opacity-80 flex items-center justify-center">
          <div className="w-1/3 flex flex-col">
            <div className="w-full  flex justify-end p-3">
              <button onClick={() => setImageViewer(false)}>
                <X size={40} color={"white"} />
              </button>
            </div>
            <Carousel>
              {productInfo.assets.square
                .concat(productInfo.assets.vertical)
                .map((s) => (
                  <img src={s} className="h-full aspect-square object-cover" />
                ))}
            </Carousel>
          </div>
        </div>
      ) : null}
      <div class="pt-[10vh]">
        {/* PRODUCTS IMAGES COLLAGE */}
        {productInfo.assets.square.length > 1 ? (
          <div class="visible lg:hidden mx-auto mt-6 max-w-2xl sm:px-6  lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
            <div
              class="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block"
              onClick={() => setImageViewer(true)}
            >
              <img
                src={productInfo.assets.vertical[0]}
                alt="Two each of gray, white, and black shirts laying flat."
                class="h-full lg:h-[85vh] w-full object-cover object-center lg:hover:scale-105 duration-300 hover:cursor-pointer "
              ></img>
            </div>
            <div class="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
              <div
                class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg"
                onClick={() => setImageViewer(true)}
              >
                <img
                  src={productInfo.assets.square[0]}
                  alt="Model wearing plain black basic tee."
                  class="h-full lg:h-[40vh] w-full object-cover object-center lg:hover:scale-105 duration-300 hover:cursor-pointer"
                ></img>
              </div>
              <div
                class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg"
                onClick={() => setImageViewer(true)}
              >
                <img
                  src={productInfo.assets.square[1]}
                  alt="Model wearing plain gray basic tee."
                  class="h-full lg:h-[40vh] w-full object-cover object-center lg:hover:scale-105 duration-300 hover:cursor-pointer"
                ></img>
              </div>
            </div>
            <div
              class="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 hidden lg:inline-block sm:overflow-hidden sm:rounded-lg"
              onClick={() => setImageViewer(true)}
            >
              <img
                src={productInfo.assets.vertical[1]}
                alt="Model wearing plain white basic tee."
                class="h-full lg:h-[85vh] w-full object-cover lg:hover:scale-105 duration-300 hover:cursor-pointer"
              ></img>
            </div>
            <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 lg:hidden sm:overflow-hidden sm:rounded-lg">
              <Carousel>
                {productInfo.assets.square
                  .concat(productInfo.assets.vertical)
                  .map((s) => (
                    <img
                      src={s}
                      className="w-full aspect-square object-cover"
                    />
                  ))}
              </Carousel>
            </div>
          </div>
        ) : null}

        <div class="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div class="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 class="visible lg:hidden text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {productInfo.name}
            </h1>
          </div>
          

          <div class="mt-4 lg:row-span-3 lg:mt-0">
          <h1 class="hidden lg:inline-block text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              {productInfo.name}
            </h1>
            <div class="hidden lg:inline-block my-3">
                <p class="text-base text-gray-900">{productInfo.mainDesc}</p>
              </div>
            {/* CTA BUTTONS */}
            <button
              // to={`/direct-checkout/${productInfo._id}/${selectedSize}`}
              onClick={handleDirectBuy}
              class="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white hover:bg-gray-800 lg:hover:scale-105 duration-300"
            >
              Acheter
            </button>
            <button
              onClick={() => {
                setSizeError(false);
                if (
                  cartItems.filter((item) => item._id === productInfo._id)
                    .length > 0
                ) {
                  removeFromCart(productInfo);
                } else {
                  if (selectedSize !== null) {
                    if (
                      cartItems.filter((item) => item._id === productInfo._id)
                        .length === 0
                    ) {
                      addToCart(productInfo, { sizeValue: selectedSize });
                    }
                  } else {
                    setSizeError(true);
                  }
                }
              }}
              class="mt-5 mb-2 flex w-full items-center justify-center rounded-md border border-gray-200  px-8 py-3 text-base font-medium hover:bg-gray-300 lg:hover:scale-105 duration-300"
            >
              {cartItems.filter((item) => item._id === productInfo._id)
                .length === 0
                ? "Ajouter au panier"
                : "Retirer du panier"}
            </button>
            {/* error */}
            {sizeError === true ? (
              <div className="w-full flex justify-center">
                <h3 className="self-center text-xs font-bold text-red-700">
                  Veuillez choisir une taille.
                </h3>
              </div>
            ) : null}

            <h2 class="sr-only">Product information</h2>
            {/* PRICE AND WISHLIST */}
            <div className="w-full flex items-center justify-between mt-6 border-b border-gray-200 pb-3">
              <p class="text-3xl tracking-tight text-gray-900">
                {productInfo.price}€
              </p>
              <button onClick={() => handleAddToWishlist(productInfo._id)}>
                {isLog &&
                userData?.wishlist?.filter((item) => item === productInfo._id).length >
                  0 ? (
                  <FaHeart className="text-2xl hover:cursor-pointer hover:scale-105 duration-200 mr-3" />
                ) : (
                  <FaRegHeart className="text-2xl hover:cursor-pointer hover:scale-105 duration-200 mr-3" />
                )}
              </button>
            </div>
              {/* SELLER BLOCK */}
            <div className="w-full mt-5 border border-gray-200 rounded-md px-3 py-5 flex items-center hover:cursor-pointer hover:scale-105 hover:border-2 hover:border-black duration-300">
              <div className="flex">
                <img
                  src={productInfo.seller.imageLink}
                  alt=""
                  className="w-12 aspect-square rounded-full"
                />
                <div className="ml-2">
                  <h1 className="font-bold">{productInfo.seller.name}</h1>
                  <div className="flex items-center">
                    <FaLocationDot className="text-gray-500" />
                    <h1 className="text-gray-600 text-xs">
                      {productInfo.seller.country}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-end ">
                {productInfo.seller.isCertified ? (
                  <div className="flex items-center">
                    <AiFillSafetyCertificate />

                    <h2 className="ml-1">Créateur certifié</h2>
                  </div>
                ) : null}
              </div>
            </div>
                {/* BRANDS AND SHIT */}
            <div className="mt-6">
              <h1>
                Marques:{" "}
                {productInfo.additionalInfo.brand.map((item, index) =>
                  index === productInfo.additionalInfo.brand.length - 1
                    ? `${item.name}`
                    : `${item.name}, `
                )}
              </h1>
              <h1>
                Couleurs:{" "}
                {productInfo.additionalInfo.color.map((item, index) =>
                  index === productInfo.additionalInfo.color.length - 1
                    ? `${item.name}`
                    : `${item.name}, `
                )}
              </h1>
              <h1>
                Matériaux:{" "}
                {productInfo.additionalInfo.material.map((item, index) =>
                  index === productInfo.additionalInfo.material.length - 1
                    ? `${item}`
                    : `${item}, `
                )}
              </h1>
            </div>

            <div class="hidden mt-6">
              <h3 class="sr-only">Reviews</h3>
              <div class="flex items-center">
                <div class="flex items-center">
                  <svg
                    class="text-gray-900 h-5 w-5 flex-shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="text-gray-900 h-5 w-5 flex-shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="text-gray-900 h-5 w-5 flex-shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="text-gray-900 h-5 w-5 flex-shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    class="text-gray-200 h-5 w-5 flex-shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <p class="sr-only">4 out of 5 stars</p>
                <a
                  href="#"
                  class="ml-3 text-sm font-medium text-gray-600 hover:text-gray-500"
                >
                  117 avis
                </a>
              </div>
            </div>

            <div className="hidden mt-10">
              <h3 class="text-sm font-medium text-gray-900">Marque(s)</h3>
              <div className="flex items-center space-x-3 mt-3 px-1">
                {productInfo.additionalInfo.brand.map((brand) => (
                  <div className="flex flex-col items-center hover:cursor-pointer">
                    {/* <img
                      src={require(`../../assets/images/${brand.asset}`)}
                      className="w-10 h-10 rounded-full"
                      alt=""
                      srcset=""
                    /> */}
                    <p className="text-xs mt-1 font-bold">{brand.name}</p>
                  </div>
                ))}
              </div>
            </div>
            {/* TAILLES */}
            <form class="">
              <div className="hidden">
                <h3 class="text-sm font-medium text-gray-900">Couleur</h3>

                <fieldset class="mt-4">
                  <legend class="sr-only">Choisir une couleur</legend>
                  <div class="flex items-center space-x-3">
                    {productInfo.additionalInfo.color.map((color) => (
                      <label class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-gray-400">
                        <input
                          type="radio"
                          name="color-choice"
                          value="White"
                          class="sr-only"
                          aria-labelledby="color-choice-0-label"
                        ></input>
                        <span id="color-choice-0-label" class="sr-only">
                          {color.name}
                        </span>
                        <span
                          aria-hidden="true"
                          className={`h-8 w-8 bg-[${color.hex}] rounded-full border border-black border-opacity-10`}
                        ></span>
                      </label>
                    ))}

                    {/* <label class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-gray-400">
                      <input
                        type="radio"
                        name="color-choice"
                        value="White"
                        class="sr-only"
                        aria-labelledby="color-choice-0-label"
                      ></input>
                      <span id="color-choice-0-label" class="sr-only">
                        White
                      </span>
                      <span
                        aria-hidden="true"
                        class="h-8 w-8 bg-white rounded-full border border-black border-opacity-10"
                      ></span>
                    </label>

                    <label class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-gray-400">
                      <input
                        type="radio"
                        name="color-choice"
                        value="Gray"
                        class="sr-only"
                        aria-labelledby="color-choice-1-label"
                      ></input>
                      <span id="color-choice-1-label" class="sr-only">
                        Gray
                      </span>
                      <span
                        aria-hidden="true"
                        class="h-8 w-8 bg-gray-200 rounded-full border border-black border-opacity-10"
                      ></span>
                    </label>

                    <label class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-gray-900">
                      <input
                        type="radio"
                        name="color-choice"
                        value="Black"
                        class="sr-only"
                        aria-labelledby="color-choice-2-label"
                      ></input>
                      <span id="color-choice-2-label" class="sr-only">
                        Black
                      </span>
                      <span
                        aria-hidden="true"
                        class="h-8 w-8 bg-gray-900 rounded-full border border-black border-opacity-10"
                      ></span>
                    </label> */}
                  </div>
                </fieldset>
              </div>
              {/* SIZOU ZEBI */}
              {productInfo.additionalInfo.size.availableSizes.length > 0 ? (
                <div class="mt-10">
                  <div class="flex items-center justify-between">
                    <h3 class="text-sm font-medium text-gray-900">
                      Taille{" "}
                      {productInfo.additionalInfo.size.availableSizes.length ===
                      1
                        ? "unique"
                        : ""}
                    </h3>
                    <a
                      href="#"
                      class="text-xs font-medium text-gray-600 hover:text-gray-500"
                    >
                      {productInfo.additionalInfo.size.sizeType}
                    </a>
                  </div>

                  <fieldset class="mt-4">
                    <legend class="sr-only">Choose a size</legend>
                    <div class="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                      {sizes
                        .filter(
                          (item) =>
                            item.category ===
                            productInfo.category
                        )[0]
                        .options.map((size) => (
                          <button
                            disabled={
                              productInfo.additionalInfo.size.availableSizes.filter(
                                (item) => item === size
                              ).length === 0 || selectedSize === size
                            }
                            onClick={() => {
                              setSizeError(false);
                              setSelectedSize(size);
                            }}
                            className={
                              selectedSize === size
                                ? "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase  focus:outline-none sm:flex-1 sm:py-6 cursor-pointer bg-gray-100 text-gray-900 shadow-sm font-bold"
                                : productInfo.additionalInfo.size.availableSizes.filter(
                                    (item) => item === size
                                  ).length > 0
                                ? "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6 cursor-pointer bg-white text-gray-900 shadow-sm"
                                : "group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6 cursor-not-allowed bg-gray-50 text-gray-200"
                            }
                          >
                            <input
                              type="radio"
                              name="size-choice"
                              value="XS"
                              class="sr-only"
                              aria-labelledby="size-choice-1-label"
                            ></input>
                            <span id="size-choice-1-label">{size}</span>

                            <span
                              class="pointer-events-none absolute -inset-px rounded-md"
                              aria-hidden="true"
                            ></span>
                            {/* TEST */}
                            {productInfo.additionalInfo.size.availableSizes.filter(
                              (item) => item === size
                            ).length === 0 ? (
                              <span
                                aria-hidden="true"
                                class="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                              >
                                <svg
                                  class="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line
                                    x1="0"
                                    y1="100"
                                    x2="100"
                                    y2="0"
                                    vector-effect="non-scaling-stroke"
                                  />
                                </svg>
                              </span>
                            ) : null}
                          </button>
                        ))}
                    </div>
                  </fieldset>
                </div>
              ) : null}

              
            </form>
          </div>

          <div class="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-0">
          <div className="w-[90%] mx-auto rounded-md">
              <Carousel>
                {productInfo.assets.square
                  .concat(productInfo.assets.vertical)
                  .map((s) => (
                    <img src={s} />
                  ))}
              </Carousel>
            </div>
            {/* DESCRIPTION */}
            <div>
              <h3 class="sr-only">Description</h3>

              <div class="space-y-6 lg:mt-6">
                <p class="text-base text-gray-900">{productInfo.mainDesc}</p>
              </div>
            </div>
            {/* ADDITIONAL MEDIA */}
            {/* {productInfo.additionalMedia.mediaType ===
            "none" ? null : productInfo.additionalMedia.mediaType ===
              "image" ? (
              <div className="flex-1 hidden lg:inline-flex justify-center mt-10">
                <img
                  src={productInfo.additionalMedia.mediaUrl}
                  className="w-[60%] rounded-md aspect-square object-cover"
                />
              </div>
            ) : productInfo.additionalMedia.mediaType === "video" ? (
              <div className="flex-1 hidden lg:inline-flex justify-center mt-10">
                <img
                  src={productInfo.additionalMedia.mediaUrl}
                  className="w-[60%] rounded-md aspect-square object-cover"
                />
              </div>
            ) : null} */}

            {/* <div class="mt-10">
              <h3 class="text-sm font-medium text-gray-900">Highlights</h3>

              <div class="mt-4">
                <ul role="list" class="list-disc space-y-2 pl-4 text-sm">
                  <li class="text-gray-400">
                    <span class="text-gray-600">Hand cut and sewn locally</span>
                  </li>
                  <li class="text-gray-400">
                    <span class="text-gray-600">
                      Dyed with our proprietary colors
                    </span>
                  </li>
                  <li class="text-gray-400">
                    <span class="text-gray-600">
                      Pre-washed &amp; pre-shrunk
                    </span>
                  </li>
                  <li class="text-gray-400">
                    <span class="text-gray-600">Ultra-soft 100% cotton</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mt-10">
              <h2 class="text-sm font-medium text-gray-900">Details</h2>

              <div class="mt-4 space-y-6">
                <p class="text-sm text-gray-600">
                  The 6-Pack includes two black, two white, and two heather gray
                  Basic Tees. Sign up for our subscription service and be the
                  first to get new, exciting colors, like our upcoming
                  &quot;Charcoal Gray&quot; limited release.
                </p>
              </div>
            </div> */}
            
            <div class="space-y-4 mt-10">
              <Item title="Livraison">
                {productInfo.delivery.buyingType === "buy-now"
                  ? `Cet article est actuellement en stock, il vous sera expedié dès validation de votre commande. Date de livraison estimée: ${dayjs(
                      getDeliveryDate(productInfo)
                    )
                      .locale("fr")
                      .format("DD MMMM")}.`
                  : productInfo.delivery.buyingType === "pre-order"
                  ? `Cet article est disponible en pré-commande, il vous sera réalisé sur-mesure après validation de votre commande. Date de livraison estimée: ${dayjs(
                      getDeliveryDate(productInfo)
                    )
                      .locale("fr")
                      .format("DD MMMM")}.`
                  : ""}
              </Item>
              <Item title="Authentification">
                Avec notre service d'authenfication, ce vêtement upcyclé est
                livré avec certificat d'authenticité délivré par nos soins.
                Lorsque vous achetez une création sur notre site vous achetez
                une création du dit Créateur et non une création de la marque
                dont la pièce upcyclée provient.
              </Item>
            </div>
            {productInfo.delivery.buyingType === "pre-order" ? (
              <div className="w-full px-8 mt-5">
                <p className="text-[11px]">
                  Chaque article vendu lors d'une pré-commande est fait
                  sur-mesure à la main, ce qui le rend unique. Par conséquent,
                  il est susceptible de présenter de légères variations par
                  rapport aux modèles photographiques présentés sur le site.
                  Nous nous efforçons de fournir des images représentatives de
                  nos produits, cependant, veuillez noter que ces variations
                  sont inhérentes à la nature artisanale de nos articles. Nous
                  vous remercions de votre compréhension.
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {/* <h2 className="text-xs tracking-widest font-bold self-start ml-3 lg:ml-28">
          RECOMMANDATIONS
        </h2>
        <h1 className="text-4xl font-main self-start ml-3 lg:ml-28 mt-2">
          Vous pouriez aussi aimer..
        </h1>
        <div className="w-full px-0 lg:px-10 flex flex-col lg:flex-row my-8 space-x-0 lg:space-x-4 items-center justify-between">
          <Link
            to="/products/65f1b1088ddd79b88dace33e"
            className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer hover:scale-[102%] duration-200"
          >
            <img
              src={require("../../assets/images/arcteryx-shoe.jpg")}
              className="w-[100%]"
              alt="lol"
            />
            <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
              Snow Boot Arc'Teryx
            </h1>
            <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
              189.99€
            </h2>
          </Link>
          <div
            to="/products/nike-purse-shoe"
            className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer"
          >
            <img
              src={require("../../assets/images/nike-shoe.jpg")}
              className="w-[100%]"
              alt="lol"
            />
            <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
              Nike Purse Shoe
            </h1>
            <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
              299.99€
            </h2>
          </div>
          <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
            <img
              src={require("../../assets/images/salomon-shoe.jpg")}
              className="w-[100%]"
              alt="lol"
            />
            <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
              Salomon Garden Party
            </h1>
            <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
              249.99€
            </h2>
          </div>
          <div className="flex flex-col flex-1 mb-3 lg:mb-0 hover:cursor-pointer">
            <img
              src={require("../../assets/images/nb-shoe.jpg")}
              className="w-[100%]"
              alt="lol"
            />
            <h1 className="font-main text-lg mt-2 ml-1 lg:ml-0">
              New Balance x Rick Owens
            </h1>
            <h2 className="font-bold tracking-wider text-xs ml-1 lg:ml-0">
              399.99€
            </h2>
          </div>
        </div> */}
        <NewFooter />
      </div>
    </div>
  );
};

export default ProductLayoutTemp;
