import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Contact from "./pages/Contact";
import Legal from "./pages/misc/Legal";
import CGV from "./pages/misc/CGV";
import ArcShoe from "./pages/products/ArcShoe";
import NikeShoe from "./pages/products/NikeShoe";
import QuickCheckout from "./pages/checkout/QuickCheckout";
import Login from "./pages/auth/Login";
import PersistLogin from "./components/routing/PersistLogin";
import RequireAuth from "./components/routing/RequireAuth";
import Wishlist from "./pages/user/Wishlist";
import Register from "./pages/auth/Register";
import UserProfile from "./pages/user/UserProfile";
import Orders from "./pages/user/Orders";
import EditProfile from "./pages/user/settings/EditProfile";
import ChangePwd from "./pages/user/settings/ChangePwd";
import HandleAccount from "./pages/user/settings/HandleAccount";
import Article from "./components/Article";
import ArticleTwo from "./components/ArticleTwo";
import ArticleThree from "./components/ArticleThree";
import NewCart from "./components/newcart/NewCart";
import FooterTwo from "./components/layout/FooterTwo";
import HomeTest from "./components/HomeTest";
import Maintenance from "./components/Maintenance";
import MaintenanceBase from "./components/MaintenanceBase";
import BecomeSeller from "./pages/sellers/BecomeSeller";

function App() {
  return (
    <Routes>
      {/* PUBLIC */} 

      <Route element={<PersistLogin />}>
        <Route path="/">
          <Route index element={<HomeTest />} />
        </Route>
        {/* PRODUCTS */}
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/products/:productId" element={<Product />} />
        {/* CHECKOUT */}
        <Route path="/cart" element={<NewCart />} />
        <Route path="/checkout" element={<Checkout />} />
        {/* MISC */}
        <Route path="/about" element={<Maintenance />} />
        <Route path="/contact" element={<ArticleThree />} />
        <Route path="/become-seller" element={<BecomeSeller />} />
        <Route path="/mentions-legales" element={<Legal />} />
        <Route path="/cgv" element={<CGV />} />
        {/* AUTH */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* USER BASED */}
        <Route element={<RequireAuth />}>
          <Route path="/wishlist" element={<Wishlist />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/profil">
            <Route index element={<UserProfile />} />
            <Route path="edit" element={<MaintenanceBase />} />
            <Route path="password" element={<ChangePwd />} />
            <Route path="account" element={<MaintenanceBase />} />
          </Route>
        </Route>
        {/* <Route element={<RequireAuth />}>
          <Route path="/profil/edit" element={<EditProfile />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/profil/password" element={<ChangePwd />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/profil/account" element={<HandleAccount />} />
        </Route> */}
       
          <Route path="/orders" element={<Maintenance />} />
       

        {/* MEH */}
        <Route path="/direct-checkout">
          <Route index element={<QuickCheckout />} />
          <Route path=":productId" element={<QuickCheckout />} />
          <Route path=":productId/:size" element={<QuickCheckout />} />
        </Route>

        {/* 404 NOT FOUND */}
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
