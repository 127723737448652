import { Outlet } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/auth";

import * as Loader from "react-spinners";
import { GiPaperPlane } from "react-icons/gi";


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { refreshUserData, setIsLog, userData } = useContext(AuthContext);


  useEffect(() => {
    let isMounted = true;

    const verifyLogin = async () => {
      if (isMounted) {
        try {
          const status = await refreshUserData();
          if (status === 200) {
            setIsLog(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    verifyLogin();

    return () => (isMounted = false);
  }, []);

  return isLoading ? (
    <div className="flex flex-1 flex-col h-screen md:h-[70vh] items-center justify-center">
      <div className="flex flex-col items-center justify-center space-y-3 space-x-2 mb-5 text-2xl">
        <img src={require('../../assets/images/favicon-png.png')} className="w-10 h-10" alt="" />
        <h1 className="font-main">no context</h1>
      </div>
      <Loader.BarLoader
        color={"#000000"}
        loading={true}
        cssOverride={override}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <Outlet />
  );
};

export default PersistLogin;
