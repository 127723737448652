import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import axios from "../../api/axios";
import * as Loader from "react-spinners";
import Lottie from "lottie-react";
import successAnimation from "../../assets/lotties/success-animation.json";

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,24}$/;

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Register = () => {
  const { refreshUserData, setIsLog } = useContext(AuthContext);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [usernameErr, setUsernameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [emailStatus, setEmailStatus] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [registerErr, setRegisterErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [cgvAccepted, setCgvAccepted] = useState(false);

  const [registerStep, setRegisterStep] = useState("email"); // email, info, password, confirm

  useEffect(() => {
    if (EMAIL_REGEX.test(email)) {
      setEmailErr("");
    }
    if (!EMAIL_REGEX.test(email) && emailFocus && email.length > 5) {
      setEmailErr("Veuillez saisir une adresse mail valide.");
    }
    if (!emailFocus) {
      setEmailErr("");
    }
    if (email === "" && emailFocus) {
      setEmailErr("");
    }
  }, [email]);

  useEffect(() => {
    if (PWD_REGEX.test(password)) {
      setPasswordErr(false);
    }
    if (!PWD_REGEX.test(password) && passwordFocus) {
      setPasswordErr(true);
    }
    if (!passwordFocus) {
      setPasswordErr(false);
    }
    if (password === "" && passwordFocus) {
      setPasswordErr(false);
    }
  }, [password]);

  useEffect(() => {
    setNameError(false);
  }, [firstName, lastName]);

  useEffect(() => {
    if (USER_REGEX.test(username)) {
      setUsernameErr(false);
    }
    if (!USER_REGEX.test(username) && usernameFocus) {
      setUsernameErr(true);
    }
    if (!usernameFocus) {
      setUsernameErr(false);
    }
    if (username === "" && usernameFocus) {
      setUsernameErr(false);
    }
  }, [username]);

  const registerUser = async () => {
    // e.preventDefault();
    if (
      USER_REGEX.test(username) &&
      PWD_REGEX.test(password) &&
      EMAIL_REGEX.test(email)
    ) {
      setError("");
      setIsLoading(true);
      const data = {
        login: {
          email: email,
          phone: "none",
          password: password,
        },
        info: {
          username: username,
          profilePicture:
            "https://ncproducts.s3.eu-west-3.amazonaws.com/avatar-pp.jpeg",
          firstName: firstName,
          lastName: lastName,
        },
        misc: {
          hasSubscribedNewsletter: false,
        },
      };
      try {
        const response = await axios.post("/auth/register", data);
        if (response.status == 201) {
          localStorage.setItem("token", response.data.token)
          await refreshUserData();
          setIsLoading(false);
          setIsLog(true);
          // navigate("/");
          setRegisterStep("confirm")
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 400) {
          setRegisterErr(err.response.data.message);
        } else {
          setRegisterErr(
            "Une erreur est survenue. Veuillez réessayer ultérieurement."
          );
        }
        setIsLoading(false);
      }
    } else {
      setRegisterErr("Veuillez vérifier vos informations.");
    }
  };

  const checkEmail = async () => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailErr("Veuillez saisir une adresse mail valide.");
    } else {
      const body = {
        email: email,
      };
      setIsLoading(true);
      try {
        const response = await axios.post("/auth/checkemail", body);
        if (response.status === 200) {
          if (response.data.result === "success") {
            setRegisterStep("info");
          }
          if (response.data.result === "error") {
            setEmailErr(response.data.message);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNextEmail = async () => {
    setIsLoading(true);
    await checkEmail();
    if (emailStatus === "success") {
      setRegisterStep("info");
      setIsLoading(false);
    } else {
      setEmailErr("deja pri");
      setIsLoading(false);
    }
  };

  const checkUsername = async () => {
    setNameError(false);
    if (firstName.length < 2 || lastName.length < 2) {
      setUsernameErr("Ces champs sont obligatoires.");
      setNameError(true);
    } else {
      if (!USER_REGEX.test(username)) {
        setUsernameErr(
          "Le nom d'utilisateur peut uniquement comporter lettres et chiffres. (4-24 charactères)"
        );
      } else {
        const body = {
          username: username,
        };
        try {
          const response = await axios.post("/auth/checkusername", body);
          if (response.status === 200) {
            if (response.data.result === "success") {
              setRegisterStep("password");
            }
            if (response.data.result === "error") {
              setUsernameErr(response.data.message);
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const checkPassword = async () => {
    setRegisterErr("")
    if(!cgvAccepted) {
      setRegisterErr("Vous devez accepter les CGV pour vous inscrire.")
    } else {
      if (!PWD_REGEX.test(password)) {
        setRegisterErr("Le mot de passe doit faire entre 8 et 24 characteres, comporter au moins un chiffre, une lettre, une majuscule et un charactere special (?!%...)");
      } else {
        if(password !== passwordConfirm) {
          setRegisterErr("Les mots de passe doivent être identiques.")
        } else {
          registerUser()
        }
      }
    }
  }

  return (
    <div className="w-full h-screen font-main">
      <div
        className={`flex min-h-full flex-1 flex-col ${
          registerStep !== "confirm" ? "justify-between" : ""
        } px-6 py-12 lg:px-8`}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Link className="mx-auto w-auto" to="/">
            <img
              className="mx-auto h-14 w-auto"
              src={require("../../assets/images/hero-logo.PNG")}
              alt="No Context - Vetements upcyclés custom"
            />
          </Link>
          {registerStep !== "confirm" ? (
            <h2 className="mt-10 text-center text-2xl leading-9 tracking-tight text-gray-900">
              Créer un compte
            </h2>
          ) : null}
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-4" action="#" method="POST">
            {registerStep === "email" ? (
              <div>
                {/* EMAIL BLOCK */}
                <div>
                  <label
                    htmlFor="email"
                    className="ml-2 block text-sm font-medium leading-6 text-gray-900"
                  >
                    Adresse Mail
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                        emailErr !== null && emailErr !== ""
                          ? "focus:ring-red-300"
                          : "focus:ring-gray-600"
                      } sm:text-sm sm:leading-6`}
                    />
                  </div>
                </div>
                {emailErr !== null && emailErr !== "" ? (
                  <p className="text-red-500 text-xs ml-2 mt-1">{emailErr}</p>
                ) : null}
                <div className="flex items-center justify-center w-full">
                  <button
                    // type="submit"
                    onClick={checkEmail}
                    disabled={isLoading}
                    className={`mt-5 flex w-[60%] justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:scale-105 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isLoading ? "animate-pulse" : ""
                    }`}
                  >
                    {isLoading ? (
                      <Loader.PuffLoader
                        color={"#fff"}
                        loading={true}
                        cssOverride={override}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Suivant"
                    )}
                  </button>
                </div>
              </div>
            ) : registerStep === "info" ? (
              <div>
                <div className="mb-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 ">
                  <div className="sm:col-span-3 ">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Prénom
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={firstName}
                        autoComplete="given-name"
                        onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                          nameError
                            ? "ring-red-300 ring-2"
                            : "ring-gray-300 ring-1"
                        } focus:ring-gray-600 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nom
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        value={lastName}
                        id="last-name"
                        onChange={(e) => setLastName(e.target.value)}
                        autoComplete="family-name"
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                          nameError
                            ? "ring-red-300 ring-2"
                            : "ring-gray-300 ring-1"
                        } focus:ring-gray-600 sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>
                </div>
                {/* USERNAME BLOCK */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nom d'utilisateur
                  </label>
                  <div className="mt-2">
                    <input
                      id="username"
                      name="username"
                      type="username"
                      autoComplete="username"
                      // required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onFocus={() => setUsernameFocus(true)}
                      onBlur={() => setUsernameFocus(false)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                {usernameErr !== "" && usernameErr !== null ? (
                  <p className="text-red-500 text-xs text-center self-center mt-3">
                    {usernameErr}
                  </p>
                ) : null}

                <div className="mt-5 flex space-x-3 items-center justify-center w-full">
                  <button
                    // type="submit"
                    onClick={() => setRegisterStep("email")}
                    disabled={isLoading}
                    className={`mt-5 flex w-[60%] justify-center rounded-md bg-gray-100 border border-gray-200 text-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:scale-105 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isLoading ? "animate-pulse" : ""
                    }`}
                  >
                    Retour
                  </button>
                  <button
                    // type="submit"
                    onClick={checkUsername}
                    disabled={isLoading}
                    className={`mt-5 flex w-[60%] justify-center rounded-md bg-black border border-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:scale-105 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isLoading ? "animate-pulse" : ""
                    }`}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            ) : registerStep === "password" ? (
              <div>
                {/* PASSWORD BLOCK */}
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Mot de passe
                    </label>
                    <div className="text-sm">
                      {/* <a
                    href="#"
                    className="text-xs font-semibold text-gray-500 hover:text-gray-600"
                  >
                    Mot de passe oublié?
                  </a> */}
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setPasswordFocus(true)}
                      onBlur={() => setPasswordFocus(false)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                {passwordErr ? (
                  <p className="text-red-500 text-xs self-center mt-2">
                    Le mot de passe doit faire entre 8 et 24 characteres,
                    comporter au moins un chiffre, une lettre, une majuscule et
                    un charactere special (?!%...)
                  </p>
                ) : null}
                {/* CONFIRM PASSWORD BLOCK */}
                <div>
                  <div className="flex items-center justify-between mt-3">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Confirmer mot de passe
                    </label>
                    <div className="text-sm">
                      {/* <a
                    href="#"
                    className="text-xs font-semibold text-gray-500 hover:text-gray-600"
                  >
                    Mot de passe oublié?
                  </a> */}
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      // autoComplete="current-password"
                      required
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      //  onFocus={() => setEmailFocus(true)}
                      //  onBlur={() => setEmailFocus(false)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                
                <div className="w-full h-12  mt-5 flex items-center">
                  <input
                    type="checkbox"
                    checked={cgvAccepted}
                    onChange={() => setCgvAccepted(prev => !prev)}
                    name=""
                    id=""
                    className="rounded-sm bg-transparent ring-1 focus:ring-2 ring-gray-400 border border-gray-300 w-5 h-5 focus:ring-gray-600 text-black"
                  />
                  <p className="text-xs ml-2 text-gray-600">
                    J'accepte les conditions générales de ventes de No Context
                    et affirme en avoir pris conscience.
                  </p>
                </div>
                {registerErr !== "" ? (
                  <p className="text-red-500 text-xs text-center self-center mt-2">
                    {registerErr}
                  </p>
                ) : null}
                <div className="mt-5 flex space-x-3 items-center justify-center w-full">
                  <button
                    // type="submit"
                    onClick={() => setRegisterStep("info")}
                    disabled={isLoading}
                    className={`mt-5 flex w-[60%] justify-center rounded-md bg-gray-100 border border-gray-200 text-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:scale-105 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isLoading ? "animate-pulse" : ""
                    }`}
                  >
                    Retour
                  </button>
                  <button
                    // type="submit"
                    onClick={checkPassword}
                    disabled={isLoading}
                    className={`mt-5 flex w-[60%] justify-center rounded-md bg-black border border-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:scale-105 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      isLoading ? "animate-pulse" : ""
                    }`}
                  >
                    Inscription
                  </button>
                </div>
              </div>
            ) : registerStep === "confirm" ? (
              <div className="w-full flex flex-col items-center">
                <h1 className="text-2xl">Bienvenue sur No Context!</h1>
                <Lottie
                  animationData={successAnimation}
                  className="w-40"
                  loop={false}
                />
                <p className="text-center text-sm">
                  Vous allez recevoir un mail pour confirmer votre addresse
                  électronique.{" "}
                </p>
                <Link
                  className="px-3 py-2 rounded-md bg-black mt-3"
                  to="/profil"
                >
                  <h1 className="text-white text-sm">Continuer</h1>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        {registerStep !== "confirm" ? (
          <p className="mt-10 text-center text-sm text-gray-500 flex-1 w-full justify-end">
            Vous avez déjà un compte?{" "}
            <Link
              href="#"
              to="/login"
              className="font-semibold leading-6 text-gray-700 hover:text-gray-600 hover:underline"
            >
              Connectez-vous
            </Link>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Register;
