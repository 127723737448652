import axios from 'axios';

// const BASE_URL = 'http://192.168.1.75:8080/api/v1';
// const BASE_URL = 'https://social-planner-mzl-api.herokuapp.com/api/v1'
// const BASE_URL = 'http://172.20.10.6:8080/api/v1';
// const BASE_URL = 'http://192.0.0.2:8080/api/v1';
// 192.0.0.1
// const BASE_URL = 'http://192.168.1.87:8080/api/v1';
// const BASE_URL = "http://169.254.184.40:8080/api/v1"
const BASE_URL = "https://nc-api-test-o4h1.onrender.com/api/v1"
// const BASE_URL = "http://localhost:8080/api/v1"


export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem("token")}`,
    },
});