import { Fragment, useEffect, useState, useContext, useRef } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import Header from "../components/layout/Header";
import { CartContext } from "../context/cart";
import SideCart from "../components/layout/SideCart";
import ClipLoader from "react-spinners/ClipLoader";
import { BarLoader, DotLoader } from "react-spinners";
import axios from "../api/axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import HeaderAlt from "../components/layout/HeaderAlt";

const PageButtonsRender = ({ sortedProducts, pageNumber, setPageNumber }) => {
  const [buttons, setButtons] = useState([]);
  // Math.ceil(sortedProducts.length / 10)

  const getButtonsList = async () => {
    let base = [];
    if(sortedProducts < 7) {
      for (let i = 0; i < sortedProducts; i++) {
        base.push(i + 1);
      }
    }
    if(sortedProducts >= 7) {
      for (let i = 0; i < 7; i++) {
        if(i < 3) {
          base.push(i + 1);
        }
        if(i === 3) {
          base.push("...")
        }
        if(i === 4) {
          base.push(sortedProducts - 2)
        }
        if(i === 5) {
          base.push(sortedProducts - 1)
        }
        if(i === 6) {
          base.push(sortedProducts)
        }
      }

    }
    return base;
  };

  useEffect(() => {
    const refresh = async () => {
      const arr = await getButtonsList();
      setButtons(arr);
    };
    refresh();
  }, [pageNumber, sortedProducts]);

  return buttons.map((item) => (
    <button
      aria-current="page"
      onClick={() => setPageNumber(item)}
      disabled={pageNumber === item || item === "..."}
      className={
        pageNumber === item
          ? "relative z-10 inline-flex items-center bg-black px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      }
    >
      {item}
    </button>
  ));
};

const productsList = [
  {
    id: "1234",
    name: "Nike Purse Shoe",
    price: "189.99",
    category: "Sneakers",
    description: "Bla bla bla bla",
    assets: {
      square: ["nike-shoe.jpg"],
    },
  },
  {
    id: "4567",
    name: "Snow Boots Arc'Teryx",
    price: "239.99",
    category: "Sneakers",
    description: "Bla bla bla bla",

    assets: {
      square: ["arcteryx-shoe.jpg"],
    },
  },
  {
    id: "8911",
    name: "New Balance x Rick Owens",
    price: "299.99",
    category: "Sneakers",
    description: "Bla bla bla bla",

    assets: {
      square: ["nb-shoe.jpg"],
    },
  },
  {
    id: "1613",
    name: "Salomon Garden Party",
    price: "139.99",
    category: "Sneakers",
    description: "Bla bla bla bla",

    assets: {
      square: ["salomon-shoe.jpg"],
    },
  },
  {
    id: "12556666634",
    name: "Bomber Greek Beige",
    price: "189.99",
    category: "Vestes",
    description: "Bla bla bla bla",

    assets: {
      square: ["kamad-1.png"],
    },
  },
  {
    id: "455554467",
    name: "Bomber Denim Jean",
    price: "239.99",
    category: "Vestes",
    description: "Bla bla bla bla",

    assets: {
      square: ["kamad-2.jpg"],
    },
  },
  {
    id: "8444511",
    name: "Upcycled LV Bomber",
    price: "299.99",
    category: "Vestes",
    description: "Bla bla bla bla",

    assets: {
      square: ["kamad-3.jpg"],
    },
  },
  {
    id: "161444",
    name: "Bomber Anagrammes Louis Vuitton",
    price: "139.99",
    category: "Vestes",
    description: "Bla bla bla bla",

    assets: {
      square: ["kamad-4.jpg"],
    },
  },
];
const sortOptions = [
  { name: "Plus Populaires", href: "#", current: true },
  // { name: "Mieux notés", href: "#", current: false },
  { name: "Plus récents", href: "#", current: false },
  { name: "Prix: Croissant", href: "#", current: false },
  { name: "Prix: Décroissant", href: "#", current: false },
];
const subCategories = [
  { name: "Tout", href: "#" },
  { name: "Hauts", href: "#" },
  { name: "Pantalons", href: "#" },
  { name: "Vestes", href: "#" },
  { name: "Chaussures", href: "#" },
  { name: "Accessoires", href: "#" },
];
const filters = [
  {
    id: "color",
    name: "Couleur",
    options: [
      { value: "white", label: "White", checked: false },
      { value: "beige", label: "Beige", checked: false },
      { value: "blue", label: "Blue", checked: true },
      { value: "brown", label: "Brown", checked: false },
      { value: "green", label: "Green", checked: false },
      { value: "purple", label: "Purple", checked: false },
    ],
  },
  {
    id: "category",
    name: "Catégorie",
    options: [
      { value: "new-arrivals", label: "New Arrivals", checked: false },
      { value: "sale", label: "Sale", checked: false },
      { value: "travel", label: "Travel", checked: true },
      { value: "organization", label: "Organization", checked: false },
      { value: "accessories", label: "Accessories", checked: false },
    ],
  },
  {
    id: "size",
    name: "Taille",
    options: [
      { value: "2l", label: "2L", checked: false },
      { value: "6l", label: "6L", checked: false },
      { value: "12l", label: "12L", checked: false },
      { value: "18l", label: "18L", checked: false },
      { value: "20l", label: "20L", checked: false },
      { value: "40l", label: "40L", checked: true },
    ],
  },
];
const categories = [
  { value: "sale", label: "Sneakers", checked: false },
  { value: "travel", label: "Vestes", checked: true },
  { value: "organization", label: "Organization", checked: false },
  { value: "accessories", label: "Accessories", checked: false },
];
const colors = [
  { value: "noir", label: "Noir", hex: false },
  { value: "blanc", label: "Blanc", hex: false },

];
const brands = [
  { value: "stussy", label: "Stussy", hex: false },
];
const materials = [
  { value: "sjeje", label: "Coton", hex: false },
  { value: "kzjzkje-grey", label: "Denim", hex: false },
];
const sizes = [
  {
    category: "Hauts", 
    type: "Hauts (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Pantalons", 
    type: "Pantalons (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Vestes", 
    type: "Vestes (EU)",
    options: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
  },
  {
    category: "Chaussures", 
    type: "Chaussures (EU)",
    options: ["40", "41", "42", "43", "44", "45", "46", "47"],
  },
  {
    category: "Accessoires", 
    type: "",
    options: ["", "", "", "", "", "", "", ""],

    category: "Sneakers", 
    type: "Chaussures (EU)",
    options: ["", "", "", "", "", "", "", ""],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Catalog() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { sideCartOpen, setSideCartOpen } = useContext(CartContext);

  const [products, setProducts] = useState(productsList);
  const [isLoading, setIsLoading] = useState(true);

  const catalogGridRef = useRef()

  const [testFilters, setTestFilters] = useState([
    { id: "1234", checked: true, zob: "6666" },
  ]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("Tout");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const [sortedProducts, setSortedProducts] = useState(products);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPriceInput, setMinPriceInput] = useState(null);
  const [maxPriceInput, setMaxPriceInput] = useState(null);
  const [priceInputConfirm, setPriceInputConfirm] = useState(false);

  const [searchParams] = useSearchParams();
  const target = searchParams.get("target");

  useEffect(() => {
    if (target && target.length > 1) {
      setSelectedCategory(target);
    }
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get("/products", headers);
      if (response.status == 200) {
        setProducts(response.data);
        setSortedProducts(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = () => {
    let finalResult = products;
    try {
      // FILTERING BY CATEGORY
      if (selectedCategory !== "Tout") {
        finalResult = products.filter(
          (product) => product.category === selectedCategory
        );
      }
      //FILTERING PRICE
      if (maxPrice !== null) {
        finalResult = finalResult.filter((product) => product.price < maxPrice);
      }
      if (minPrice !== null) {
        finalResult = finalResult.filter((product) => product.price > minPrice);
      }
      //FILTERING CATEGORIES
      if (selectedCategories.length > 0) {
        let base = [];
        for (let i = 0; i < selectedCategories.length; i++) {
          base = base.concat(
            finalResult.filter(
              (product) => product.category === selectedCategories[i]
            )
          );
          // finalResult = finalResult.filter((product) => product.category === selectedCategories[i])
        }
        finalResult = base;
      }
      //FILTERING SIZES
      if (selectedSizes.length > 0) {
        let base = [];
        for (let i = 0; i < finalResult.length; i++) {
          for (
            let u = 0;
            u < finalResult[i].additionalInfo.size.availableSizes.length;
            u++
          ) {
            for (let v = 0; v < selectedSizes.length; v++) {
              if (
                finalResult[i].additionalInfo.size.availableSizes[u] ===
                selectedSizes[v]
              ) {
                base.push(finalResult[i]);
              }
            }
          }
        }
        finalResult = base;
      }
      //FILTERING COLORS
      if (selectedColors.length > 0) {
        let base = [];
        for (let i = 0; i < finalResult.length; i++) {
          for (let u = 0; u < finalResult[i].additionalInfo.color.length; u++) {
            for (let v = 0; v < selectedColors.length; v++) {
              if (
                finalResult[i].additionalInfo.color[u].name ===
                selectedColors[v]
              ) {
                base.push(finalResult[i]);
              }
            }
          }
        }
        finalResult = base;
      }
      //FILTERING MATERIALS
      if (selectedMaterials.length > 0) {
        let base = [];
        for (let i = 0; i < finalResult.length; i++) {
          for (
            let u = 0;
            u < finalResult[i].additionalInfo.material.length;
            u++
          ) {
            for (let v = 0; v < selectedMaterials.length; v++) {
              if (
                finalResult[i].additionalInfo.material[u] ===
                selectedMaterials[v]
              ) {
                base.push(finalResult[i]);
              }
            }
          }
        }
        finalResult = base;
      }
      //FILTERING BRANDS
      if (selectedBrands.length > 0) {
        let base = [];
        for (let i = 0; i < finalResult.length; i++) {
          for (let u = 0; u < finalResult[i].additionalInfo.brand.length; u++) {
            for (let v = 0; v < selectedBrands.length; v++) {
              if (
                finalResult[i].additionalInfo.brand[u].name ===
                selectedBrands[v]
              ) {
                base.push(finalResult[i]);
              }
            }
          }
        }
        finalResult = base;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSortedProducts(finalResult);
    }
  };

  useEffect(() => {
    handleSort();
    // setPageNumber(1)
  }, [
    selectedCategory,
    selectedCategories,
    selectedColors,
    minPrice,
    maxPrice,
    selectedBrands,
    selectedMaterials,
    selectedSizes,
    sortedProducts
  ]);

  useEffect(() => {
    const scrollToRef = (ref) => {
      ref.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    };
    scrollToRef(catalogGridRef)
  }, [pageNumber])

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="bg-white font-main">
      <div className="w-full flex justify-center">
        <HeaderAlt backgroundColor="white" />
      </div>
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filtres
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  {/* mobile-categories-zebi */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    <ul
                      role="list"
                      className="px-4 py-3 font-medium text-gray-900"
                    >
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <div
                            onClick={() => setSelectedCategory(category.name)}
                            className="hover:cursor-pointer"
                          >
                            <p
                              className={
                                selectedCategory === category.name
                                  ? `font-bold`
                                  : `font-main hover:scale-105 duration-200`
                              }
                            >
                              {category.name}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>

                    {/* 
                    <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  Catégorie
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {categories.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-category-${optionIdx}`}
                                      name={`category[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      checked={option.checked}
                                      // defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-black focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-category-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure> 
                      
                      */}
                    {/* mobile price dropdown */}
                    <div className="border-t border-gray-200 px-4 py-6">
                      <p className="px-2">Prix</p>
                      <div className="w-full h-12 flex items-center justify-around px-5 mt-3">
                        {/* red-200 */}
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            className="p-0 w-16 h-7 border border-gray-300 rounded-sm focus:ring-black focus:border-black block text-center text-[10px]"
                            value={minPriceInput}
                            onChange={(e) => {
                              setPriceInputConfirm(true);
                              setMinPriceInput(e.target.value);
                            }}
                          />

                          <p className="text-[9px]">min</p>
                        </div>
                        <p className="text-gray-400">-</p>
                        <div className="flex flex-col items-center">
                          <input
                            type="number"
                            className="p-0 w-16 h-7 border border-gray-300 rounded-sm focus:ring-black focus:border-black block text-center text-[10px]"
                            value={maxPriceInput}
                            onChange={(e) => {
                              setPriceInputConfirm(true);
                              setMaxPriceInput(e.target.value);
                            }}
                          />

                          <p className="text-[9px]">max</p>
                        </div>
                      </div>
                      {priceInputConfirm ? (
                        <div className="w-full flex items-center justify-center mb-2">
                          <button
                            className="px-2 py-1 bg-black rounded-md hover:scale-[103%] transition duration-150"
                            onClick={(e) => {
                              e.preventDefault();
                              if (minPriceInput !== null) {
                                if (minPriceInput.length > 0) {
                                  setMinPrice(minPriceInput);
                                } else {
                                  setMinPrice(null);
                                }
                              }
                              if (maxPriceInput !== null) {
                                if (maxPriceInput.length > 0) {
                                  setMaxPrice(maxPriceInput);
                                } else {
                                  setMaxPrice(null);
                                }
                              }
                              setPriceInputConfirm(false);
                            }}
                          >
                            <h3 className="text-[9px] text-white">Confirmer</h3>
                          </button>
                        </div>
                      ) : null}
                    </div>
                    {/* mobile sizes dropdown */}
                    {selectedCategory !== "Tout" &&
                    selectedCategory !== "Accessoires" ? (
                      <Disclosure
                        as="div"
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  Taille
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-4">
                                {sizes
                                  .filter(
                                    (item) => item.category === selectedCategory
                                  )[0]
                                  .options.map((option, optionIdx) => (
                                    <div
                                      key={option}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-category-${optionIdx}`}
                                        name={`category[]`}
                                        // defaultValue={option.value}
                                        type="checkbox"
                                        checked={
                                          selectedBrands.filter(
                                            (item) => item === option
                                          ).length > 0
                                        }
                                        onChange={() => {
                                          if (
                                            selectedBrands.filter(
                                              (item) => item === option
                                            ).length > 0
                                          ) {
                                            setSelectedBrands(
                                              selectedBrands.filter(
                                                (item) => item !== option
                                              )
                                            );
                                          } else {
                                            setSelectedBrands((prev) => [
                                              ...prev,
                                              option,
                                            ]);
                                          }
                                        }}
                                        // checked={selectedCategories.filter((item) => item === option.value).length > 0}
                                        // defaultChecked={false}
                                        className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                      />
                                      <label
                                        htmlFor={`filter-category-${optionIdx}`}
                                        className="ml-3 text-sm text-gray-600"
                                      >
                                        {option}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : null}
                    {/* mobile brands dropdown */}
                    <Disclosure
                      as="div"
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                Marques
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {brands.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-category-${optionIdx}`}
                                    name={`category[]`}
                                    // defaultValue={option.value}
                                    type="checkbox"
                                    checked={
                                      selectedBrands.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    }
                                    onChange={() => {
                                      if (
                                        selectedBrands.filter(
                                          (item) => item === option.label
                                        ).length > 0
                                      ) {
                                        setSelectedBrands(
                                          selectedBrands.filter(
                                            (item) => item !== option.label
                                          )
                                        );
                                      } else {
                                        setSelectedBrands((prev) => [
                                          ...prev,
                                          option.label,
                                        ]);
                                      }
                                    }}
                                    // checked={selectedCategories.filter((item) => item === option.value).length > 0}
                                    // defaultChecked={false}
                                    className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                  />
                                  <label
                                    htmlFor={`filter-category-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    {/* mobile materials dropdown */}
                    <Disclosure
                      as="div"
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                Matériaux
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {materials.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-category-${optionIdx}`}
                                    name={`category[]`}
                                    // defaultValue={option.value}
                                    type="checkbox"
                                    checked={
                                      selectedMaterials.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    }
                                    onChange={() => {
                                      if (
                                        selectedMaterials.filter(
                                          (item) => item === option.label
                                        ).length > 0
                                      ) {
                                        setSelectedMaterials(
                                          selectedMaterials.filter(
                                            (item) => item !== option.label
                                          )
                                        );
                                      } else {
                                        setSelectedMaterials((prev) => [
                                          ...prev,
                                          option.label,
                                        ]);
                                      }
                                    }}
                                    // checked={selectedCategories.filter((item) => item === option.value).length > 0}
                                    // defaultChecked={false}
                                    className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                  />
                                  <label
                                    htmlFor={`filter-category-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    {/* mobile colors dropdown */}
                    <Disclosure
                      as="div"
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                Couleur
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {colors.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-category-${optionIdx}`}
                                    name={`category[]`}
                                    // defaultValue={option.value}
                                    type="checkbox"
                                    checked={
                                      selectedColors.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    }
                                    onChange={() => {
                                      if (
                                        selectedColors.filter(
                                          (item) => item === option.label
                                        ).length > 0
                                      ) {
                                        setSelectedColors(
                                          selectedColors.filter(
                                            (item) => item !== option.label
                                          )
                                        );
                                      } else {
                                        setSelectedColors((prev) => [
                                          ...prev,
                                          option.label,
                                        ]);
                                      }
                                    }}
                                    // checked={selectedCategories.filter((item) => item === option.value).length > 0}
                                    // defaultChecked={false}
                                    className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                  />
                                  <label
                                    htmlFor={`filter-category-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* fixedhere */}
        <div className="fixed top-0 w-screen h-screen">
          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
              <h1 className="text-4xl font-main tracking-tight text-gray-900">
                Catalogue
              </h1>

              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Trier
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                href={option.href}
                                className={classNames(
                                  option.current
                                    ? "font-medium text-gray-900"
                                    : "text-gray-500",
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button> */}
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 max-h-[80vh]">
                {/* Filters */}
                <form className="hidden lg:block overflow-y-auto max-h-[80vh] pb-20 no-scrollbar">
                  {/* MAIN CATEGORIES */}
                  <h3 className="sr-only">Categories</h3>
                  <ul
                    role="list"
                    className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900"
                  >
                    {subCategories.map((category) => (
                      <li key={category.name}>
                        <div
                          onClick={() => setSelectedCategory(category.name)}
                          className="hover:cursor-pointer"
                        >
                          <p
                            className={
                              selectedCategory === category.name
                                ? `font-bold`
                                : `font-main hover:scale-105 duration-200`
                            }
                          >
                            {category.name}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>

                  {/* PRICE INPUT */}

                  <div className="border-b border-gray-200">
                    <div className="flex w-full items-center justify-between bg-white py-2 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900 mt-1">
                        Prix
                      </span>
                    </div>
                    <div className="w-full h-12 flex items-center justify-around px-5">
                      {/* red-200 */}
                      <div className="flex flex-col items-center">
                        <input
                          type="number"
                          className="p-0 w-16 h-7 border border-gray-300 rounded-sm focus:ring-black focus:border-black block text-center text-[10px]"
                          value={minPriceInput}
                          onChange={(e) => {
                            setPriceInputConfirm(true);
                            setMinPriceInput(e.target.value);
                          }}
                        />

                        <p className="text-[9px]">min</p>
                      </div>
                      <p className="text-gray-400">-</p>
                      <div className="flex flex-col items-center">
                        <input
                          type="number"
                          className="p-0 w-16 h-7 border border-gray-300 rounded-sm focus:ring-black focus:border-black block text-center text-[10px]"
                          value={maxPriceInput}
                          onChange={(e) => {
                            setPriceInputConfirm(true);
                            setMaxPriceInput(e.target.value);
                          }}
                        />

                        <p className="text-[9px]">max</p>
                      </div>
                    </div>
                    {priceInputConfirm ? (
                      <div className="w-full flex items-center justify-center mb-2">
                        <button
                          className="px-2 py-1 bg-black rounded-md hover:scale-[103%] transition duration-150"
                          onClick={(e) => {
                            e.preventDefault();
                            if (minPriceInput !== null) {
                              if (minPriceInput.length > 0) {
                                setMinPrice(minPriceInput);
                              } else {
                                setMinPrice(null);
                              }
                            }
                            if (maxPriceInput !== null) {
                              if (maxPriceInput.length > 0) {
                                setMaxPrice(maxPriceInput);
                              } else {
                                setMaxPrice(null);
                              }
                            }
                            setPriceInputConfirm(false);
                          }}
                        >
                          <h3 className="text-[9px] text-white">Confirmer</h3>
                        </button>
                      </div>
                    ) : null}
                  </div>

                  {/* SIZE DROPDOWN */}
                  {selectedCategory !== "Tout" &&
                  selectedCategory !== "Accessoires" ? (
                    <Disclosure
                      as="div"
                      className="border-b border-gray-200 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                Taille
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {sizes
                                .filter(
                                  (item) => item.category === selectedCategory
                                )[0]
                                .options.map((option, optionIdx) => (
                                  <div
                                    key={option}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-category-${optionIdx}`}
                                      name={`category[]`}
                                      defaultValue={option}
                                      type="checkbox"
                                      onChange={() => {
                                        if (
                                          selectedSizes.filter(
                                            (item) => item === option
                                          ).length > 0
                                        ) {
                                          setSelectedSizes(
                                            selectedSizes.filter(
                                              (item) => item !== option
                                            )
                                          );
                                        } else {
                                          setSelectedSizes((prev) => [
                                            ...prev,
                                            option,
                                          ]);
                                        }
                                      }}
                                      checked={
                                        selectedSizes.filter(
                                          (item) => item === option
                                        ).length > 0
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                    />
                                    <label
                                      htmlFor={`filter-category-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-600"
                                    >
                                      {option}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : null}
                  {/* BRANDS DROPDOWN */}
                  <Disclosure
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Marque
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {brands.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-category-${optionIdx}`}
                                  name={`category[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  onChange={() => {
                                    if (
                                      selectedBrands.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    ) {
                                      setSelectedBrands(
                                        selectedBrands.filter(
                                          (item) => item !== option.label
                                        )
                                      );
                                    } else {
                                      setSelectedBrands((prev) => [
                                        ...prev,
                                        option.label,
                                      ]);
                                    }
                                  }}
                                  checked={
                                    selectedBrands.filter(
                                      (item) => item === option.label
                                    ).length > 0
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                />
                                <label
                                  htmlFor={`filter-category-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  {/* MATERIALS DROPDOWN */}
                  <Disclosure
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Matériaux
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {materials.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-category-${optionIdx}`}
                                  name={`category[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  onChange={() => {
                                    if (
                                      selectedMaterials.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    ) {
                                      setSelectedMaterials(
                                        selectedMaterials.filter(
                                          (item) => item !== option.label
                                        )
                                      );
                                    } else {
                                      setSelectedMaterials((prev) => [
                                        ...prev,
                                        option.label,
                                      ]);
                                    }
                                  }}
                                  checked={
                                    selectedMaterials.filter(
                                      (item) => item === option.label
                                    ).length > 0
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                />
                                <label
                                  htmlFor={`filter-category-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  {/* COLORS DROPDOWN */}
                  <Disclosure
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Couleur
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {colors.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-category-${optionIdx}`}
                                  name={`category[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  onChange={() => {
                                    if (
                                      selectedColors.filter(
                                        (item) => item === option.label
                                      ).length > 0
                                    ) {
                                      setSelectedColors(
                                        selectedColors.filter(
                                          (item) => item !== option.label
                                        )
                                      );
                                    } else {
                                      setSelectedColors((prev) => [
                                        ...prev,
                                        option.label,
                                      ]);
                                    }
                                  }}
                                  checked={
                                    selectedColors.filter(
                                      (item) => item === option.label
                                    ).length > 0
                                  }
                                  // defaultChecked={false}
                                  className="h-4 w-4 rounded border-gray-300 text-gray-800 focus:outline-none outline-none"
                                />
                                <label
                                  htmlFor={`filter-category-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </form>

                {/* Product grid */}
                <div className="lg:col-span-3 overflow-y-auto max-h-[80vh] pb-20" ref={catalogGridRef}>
                  {/* catalog render */}
                  {isLoading ? (
                    <div className="flex-1 h-full flex items-center justify-center">
                      <DotLoader
                        color={"black"}
                        loading={isLoading}
                        // cssOverride={override}
                        size={75}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : sortedProducts.length > 0 ? (
                    <div className="w-full flex flex-wrap px-2 lg:px-5 overflow-y-auto">
                      {sortedProducts
                        .filter(
                          (i, index) =>
                            index < pageNumber * 10 &&
                            index >= (pageNumber - 1) * 10
                        )
                        .map((product) => (
                          <Link
                            to={`/products/${product._id}`}
                            key={product.id}
                            className="mb-3 w-[50%] lg:w-[33%]  hover:cursor-pointer hover:scale-105 duration-300"
                          >
                            <img
                              src={product.assets.mainImage}
                              className="w-[100%]"
                              alt="lol"
                            />
                            <h1 className="font-main text-md lg:text-lg px-1 pt-1">
                              {product.name}
                            </h1>
                            <h2 className="font-bold text-xs tracking-wider px-1 pb-2">
                              {product.price}€
                            </h2>
                          </Link>
                        ))}
                    </div>
                  ) : (
                    <div className="flex-1 h-full flex items-center justify-center">
                      <h1 className="text-sm text-gray-500">
                        Aucun produit ne correspond.
                      </h1>
                    </div>
                  )}
                  {/* begin-test */}
                  {/* PAGINATION */}
                  {sortedProducts.length > 0 ? (
                    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                      <div className="flex flex-1 justify-between sm:hidden">
                        <button
                          href="#"
                          disabled={pageNumber === 1}
                          className={`relative inline-flex items-center rounded-md border border-gray-300 ${
                            pageNumber === 1 ? "bg-gray-200" : "bg-white"
                          } px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}
                          onClick={() => setPageNumber((prev) => prev - 1)}
                        >
                          Précédent
                        </button>
                        <button
                          href="#"
                          disabled={pageNumber === 2}
                          className={`relative inline-flex items-center rounded-md border border-gray-300 ${
                            pageNumber === 2 ? "bg-gray-200" : "bg-white"
                          } px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}
                          onClick={() => setPageNumber((prev) => prev + 1)}
                        >
                          Suivant
                        </button>
                      </div>
                      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                          <p className="text-sm text-gray-700">
                            Montre{" "}
                            <span className="font-medium">
                              {(pageNumber - 1) * 10}
                            </span>{" "}
                            à{" "}
                            <span className="font-medium">
                              {pageNumber * 10 > sortedProducts.length ? `${sortedProducts.length}` : `${pageNumber * 10}`}
                            </span>{" "}
                            de{" "}
                            <span className="font-medium">
                              {sortedProducts.length}
                            </span>{" "}
                            résultats
                          </p>
                        </div>
                        <div>
                          <nav
                            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                            aria-label="Pagination"
                          >
                            <button
                              href="#"
                              disabled={pageNumber === 1}
                              onClick={() => setPageNumber(prev => prev - 1)}
                              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                                pageNumber === 1
                                  ? "bg-gray-200"
                                  : "bg-white hover:bg-gray-50"
                              } focus:z-20 focus:outline-offset-0`}
                            >
                              <span className="sr-only">Précédent</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* Current: "z-10 bg-black text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                            {/* <p>{Math.ceil(sortedProducts.length / 10)}</p> */}
                            <PageButtonsRender
                              sortedProducts={Math.ceil(sortedProducts.length / 10)}
                              pageNumber={pageNumber}
                              setPageNumber={setPageNumber}
                            />
                            {/* <a
                              href="#"
                              aria-current="page"
                              className="relative z-10 inline-flex items-center bg-black px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                              1
                            </a>
                            <a
                              href="#"
                              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                              2
                            </a>
                            <a
                              href="#"
                              className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                            >
                              3
                            </a>
                            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                              ...
                            </span>
                            <a
                              href="#"
                              className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                            >
                              8
                            </a>
                            <a
                              href="#"
                              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                              9
                            </a>
                            <a
                              href="#"
                              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            >
                              10
                            </a> */}
                            <button
                              disabled={
                                pageNumber ===
                                Math.ceil(sortedProducts.length / 10)
                              }
                              onClick={() => setPageNumber(prev => prev + 1)}
                              href="#"
                              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                                pageNumber === Math.ceil(sortedProducts.length / 10)
                                  ? "bg-gray-200"
                                  : "bg-white hover:bg-gray-50"
                              } focus:z-20 focus:outline-offset-0`}
                            >
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}

// des « mentions CNIL » en bas du formulaire de contact ;
// un moyen de contact pour que les personnes puissent exercer leurs droits par voie électronique ;
// des mentions légales identifiant l’éditeur du site.
