import React, { useState, useContext } from "react";
import HeaderAlt from "../layout/HeaderAlt";
import { CartContext } from "../../context/cart";
import { AddressAutofill } from "@mapbox/search-js-react";
import axios from "../../api/axios";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { AuthContext } from "../../context/auth";

function NewCart() {
  const {
    cartItems,
    addToCart,
    updateDelivery,
    removeFromCart,
    clearCart,
    getCartTotal,
    getCartTotalDelivery,
  } = useContext(CartContext);
  const { isLog, userData } = useContext(AuthContext);

  const [cartStep, setCartStep] = useState("panier"); //panier, paiement, confirmation

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState(
    isLog ? userData.info.firstName : ""
  );
  const [lastName, setLastName] = useState(isLog ? userData.info.lastName : "");
  const [email, setEmail] = useState(isLog ? userData.info.email : "");

  const [addressLine, setAddressLine] = useState("");
  const [country, setCountry] = useState("France");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [cgvAccepted, setCgvAccepted] = useState(false);

  const getDeliveryDate = (item) => {
    var date = new Date();
    date.setDate(date.getDate() + item.delivery.deliveryTime);
    return date;
  };

  const getStripeUrl = async () => {
    let body = [];
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    for (let i = 0; i < cartItems.length; i++) {
      body.push({
        _id: cartItems[i]._id,
        selectedDelivery: cartItems[i].selectedDelivery,
      });
      // console.log(cartItems[i]._id)
    }
    // if (selectedDelivery === "DHL") {
    //   body.push("dhl-delivery");
    // }
    // const body = [productId]
    // const body = ["65f1b1088ddd79b88dace33e", "65f1b1088ddd79b88dace33e"]
    try {
      const response = await axios.post("/test/pay", body, headers);
      if (response.status == 200) {
        // setUserData(response.data)
        console.log(response.data);
        window.location.href = response.data.url;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePay = async (e) => {
    //MODAL CONFIRM
    e.preventDefault();
    setIsLoading(true);
    setError("");
    if (
      firstName === "" ||
      email === "" ||
      addressLine === "" ||
      city === "" ||
      region === "" ||
      postalCode === "" ||
      !cgvAccepted
    ) {
      setError("Tout les champs sont obligatoires.");
      setIsLoading(false);
    } else {
      if (1 === 2) {
        //CHECK EMAIL
        console.log("math broken");
      } else {
        //CREATE ORDER
        const body = {
          orderState: "awaiting-payment", // placed, expedited, delivered, success, issue (WILL NEED CRON JOB AFTER 14x DAYS)
          customerId: isLog ? userData?._id : "none",
          content: cartItems, //LIST OF PRODUCTS IDs to fetch later (or keep minimal info so as not to ping db too much)
          customer: {
            firstName: firstName,
            lastName: lastName,
            adressStreetLine: addressLine,
            city: city,
            region: region,
            country: country,
            postalCode: postalCode,
            notes: "none",
            contactInfo: {
              email: email,
              phone: "none",
              hasSubscribed: false,
            },
          },
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        try {
          const response = await axios.post("/orders/create", body, headers);
          if (response.status === 200) {
            //REDIRECT TO STRIPE
            getStripeUrl();
          }
        } catch (err) {
          console.log(err);
          setError("Une erreur est survenue. Veuillez réessayer plus tard.");
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="bg-white py-8 antialiased md:py-16 mx-auto relative">
      <HeaderAlt backgroundColor="white" />
      {/* BREADCRUMBS */}
      <ol className="pt-[5vh] lg:pt-0 justify-center lg:justify-start items-center flex w-full max-w-2xl text-center text-sm font-medium text-gray-500  sm:text-base lg:ml-20 lg:mt-10">
        <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
          <button
            className={`flex items-center after:mx-2 ${
              cartStep === "panier" ? "text-black" : "after:text-gray-200"
            } after:content-['/']  sm:after:hidden`}
            onClick={() => setCartStep("panier")}
          >
            <svg
              className="me-2 h-4 w-4 sm:h-5 sm:w-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Panier
          </button>
        </li>
        <li className="after:border-1 flex items-center text-primary-700 after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200  sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10">
          <button
            className={`flex items-center after:mx-2 ${
              cartStep === "paiement" ? "text-black" : "after:text-gray-200"
            } after:content-['/']  sm:after:hidden`}
          >
            <svg
              className="me-2 h-4 w-4 sm:h-5 sm:w-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Paiement
          </button>
        </li>
        <li className="flex shrink-0 items-center">
          <svg
            className="me-2 h-4 w-4 sm:h-5 sm:w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Confirmation
        </li>
      </ol>
      {cartStep === "panier" ? (
        <section className="max-w-screen-xl mx-auto px-4 lg:px-0">
          <div className="mt-6 sm:mt-8">
            <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Votre Panier
            </h2>
            <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
              <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
                {/* Products List Start */}
                <div className="space-y-6">
                  {/* Product Entry Start */}
                  {/* <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm md:p-6">
                  <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                    <a href="#" className="shrink-0 md:order-1">
                      <img className="h-20 w-20" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front.svg" alt="imac image" />
                    </a>
                    <div className="flex items-center justify-between md:order-3 md:justify-end">
                      <div className="flex items-center">
                        <button  type="button" className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100">
                          <svg className="h-2.5 w-2.5 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                          </svg>
                        </button>
                        <input type="text" className="w-10 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0" readOnly />
                        <button  type="button" className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100">
                          <svg className="h-2.5 w-2.5 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                          </svg>
                        </button>
                      </div>
                      <div className="text-end md:order-4 md:w-32">
                        <p className="text-base font-bold text-gray-900">$1,499</p>
                      </div>
                    </div>
                    <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                      <a href="#" className="text-base font-medium text-gray-900 hover:underline">PC system All in One APPLE iMac (2023) mqrq3ro/a, Apple M3, 24" Retina 4.5K, 8GB, SSD 256GB, 10-core GPU, Keyboard layout INT</a>
                      <div className="flex items-center gap-4">
                        <button type="button" className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 hover:underline">
                          <svg className="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z" />
                          </svg>
                          Add to Favorites
                        </button>
                        <button type="button" className="inline-flex items-center text-sm font-medium text-red-600 hover:underline">
                          <svg className="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6" />
                          </svg>
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                  {cartItems.length === 0 ? (
                    <div>
                      <p className="text-gray-600">Votre Panier est vide.</p>
                    </div>
                  ) : null}
                  {cartItems.map((item) => (
                    <div
                      className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm md:p-6"
                      key={item._id}
                    >
                      <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                        <a href="#" className="shrink-0 md:order-1">
                          <img
                            className="h-40 w-40"
                            src={item.assets.mainImage}
                            alt="imac image"
                          />
                        </a>
                        <div className="flex items-center justify-between md:order-3 md:justify-end">
                          {/* <div className="flex items-center">
                        <button  type="button" className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100">
                          <svg className="h-2.5 w-2.5 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                          </svg>
                        </button>
                        <input type="text" className="w-10 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0" readOnly />
                        <button  type="button" className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100">
                          <svg className="h-2.5 w-2.5 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                          </svg>
                        </button>
                      </div> */}
                          <div className="text-end md:order-4 md:w-32">
                            <p className="text-base font-bold text-gray-900">
                              {item.price}€
                            </p>
                          </div>
                        </div>
                        <div className="w-full min-w-0 flex-1 md:order-2 md:max-w-md">
                          <a
                            href="#"
                            className="text-lg font-medium text-gray-900 hover:underline"
                          >
                            {item.name}
                          </a>
                          <h3 className="text-xs text-gray-800">
                            Taille: {item.selectedSize.sizeValue} -{" "}
                            {item.additionalInfo.size.sizeType}
                          </h3>
                          {/* delivery-here */}
                          <div className="">
                            <div className="space-y-2 mt-4">
                              <div
                                className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4 hover:scale-105 duration-200 hover:cursor-pointer hover:border-black"
                                onClick={() =>
                                  updateDelivery(item, "standard", 0)
                                }
                              >
                                <div className="flex items-start">
                                  <div className="flex h-5 items-center">
                                    <input
                                      id="fedex"
                                      aria-describedby="fedex-text"
                                      type="radio"
                                      name="delivery-method"
                                      value=""
                                      className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                                      checked={
                                        item.selectedDelivery === "standard"
                                      }
                                      disabled
                                    />
                                  </div>

                                  <div className="ms-4 text-sm">
                                    <label
                                      htmlFor="fedex"
                                      className="font-medium leading-none text-gray-900 "
                                    >
                                      Livraison Standard - Offerte
                                    </label>
                                    <p
                                      id="fedex-text"
                                      className="mt-1 text-xs font-normal text-gray-500 "
                                    >
                                      Livraison estimée le{" "}
                                      {dayjs(getDeliveryDate(item))
                                        .locale("fr")
                                        .format("DD MMMM")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4 hover:scale-105 duration-200 hover:cursor-pointer hover:border-black"
                                onClick={() =>
                                  updateDelivery(item, "authenticated", 10)
                                }
                              >
                                <div className="flex items-start">
                                  <div className="flex h-5 items-center">
                                    <input
                                      id="fedex"
                                      aria-describedby="fedex-text"
                                      type="radio"
                                      name="delivery-method"
                                      value=""
                                      disabled
                                      checked={
                                        item.selectedDelivery ===
                                        "authenticated"
                                      }
                                      className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                                    />
                                  </div>

                                  <div className="ms-4 text-sm">
                                    <label
                                      htmlFor="fedex"
                                      className="font-medium leading-none text-gray-900 "
                                    >
                                      Livraison Authentifiée - 10€
                                    </label>
                                    <p
                                      id="fedex-text"
                                      className="mt-1 text-xs font-normal text-gray-500 "
                                    >
                                      Vos articles seront vérifiés et livrés
                                      avec certificat d'authenticité
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="express"
                            aria-describedby="express-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="express"
                            className="font-medium leading-none text-gray-900 "
                          >
                            $49 - Express Delivery
                          </label>
                          <p
                            id="express-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Get it today
                          </p>
                        </div>
                      </div>
                    </div> */}
                            </div>
                          </div>
                          <div className="flex items-center gap-4 mt-3">
                            <button
                              type="button"
                              className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900 hover:underline"
                            >
                              <svg
                                className="me-1.5 h-5 w-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
                                />
                              </svg>
                              Ajouter aux favoris
                            </button>
                            <button
                              type="button"
                              className="inline-flex items-center text-sm font-medium text-red-600 hover:underline"
                              onClick={() => removeFromCart(item)}
                            >
                              <svg
                                className="me-1.5 h-5 w-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18 17.94 6M18 18 6.06 6"
                                />
                              </svg>
                              Retirer du panier
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Product Entry End */}
                  {/* Other products would follow a similar pattern */}
                </div>
                {/* Products List End */}
              </div>

              {/* Order Summary Start */}
              <div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
                  <p className="text-xl font-semibold text-gray-900">
                    Récapitulatif
                  </p>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <dl className="flex items-center justify-between gap-4">
                        <dt className="text-base font-normal text-gray-500">
                          Articles
                        </dt>
                        <dd className="text-base font-medium text-gray-900">
                          {getCartTotal()}€
                        </dd>
                      </dl>

                      <dl className="flex items-center justify-between gap-4">
                        <dt className="text-base font-normal text-gray-500">
                          Frais de livraison
                        </dt>
                        <dd className="text-base font-medium text-gray-900">
                          {getCartTotalDelivery() - getCartTotal()}€
                        </dd>
                      </dl>
                      <dl className="flex items-center justify-between gap-4">
                        <dt className="text-base font-normal text-gray-500">
                          Frais de service
                        </dt>
                        <dd className="text-base font-medium text-green-600">
                          Offerts
                        </dd>
                      </dl>
                      {/* <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500">Tax</dt>
                      <dd className="text-base font-medium text-gray-900">$799</dd>
                    </dl> */}
                    </div>
                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2">
                      <dt className="text-base font-bold text-gray-900">
                        Total
                      </dt>
                      <dd className="text-base font-bold text-gray-900">
                        {getCartTotalDelivery()}€
                      </dd>
                    </dl>
                  </div>
                  <button
                    disabled={cartItems.length < 1}
                    onClick={() => setCartStep("paiement")}
                    className="flex w-full items-center justify-center rounded-lg bg-black px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-primary-300 hover:scale-105 duration-200"
                  >
                    {cartItems.length < 1 ? "Ajouter un article pour continuer" : "Procéder au paiement"}
                  </button>
                  <div className="flex items-center justify-center gap-2">
                    <span className="text-sm font-normal text-gray-500">
                      {" "}
                      ou{" "}
                    </span>
                    <a
                      href="/catalog"
                      title=""
                      className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline"
                    >
                      Explorez les articles
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 12H5m14 0-4 4m4-4-4-4"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                {/* Order Summary End */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="max-w-screen-xl mx-auto px-4 lg:px-0">
          <form action="#" className="">
            <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
              <div className="min-w-0 flex-1 space-y-8">
                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-900 ">
                    Vos Informations
                  </h2>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="name"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        Nom complet
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="Votre Nom"
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        Votre email*
                      </label>
                      <input
                        type="email"
                        id="your_email"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="contact@no-context.fr"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="street-address"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        Adresse
                      </label>
                      <AddressAutofill accessToken="pk.eyJ1Ijoic2Ftc2VsZGpvdWQiLCJhIjoiY2x1OHIxaHQ2MDIxazJvcWthbjRlaHdkdyJ9.VfhPCjYToPAKwUbOCiOqFg">
                        <input
                          type="text"
                          name="street-address"
                          id="street-address"
                          onChange={(e) => setAddressLine(e.target.value)}
                          autoComplete="street-address"
                          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                          placeholder="13 rue Roquette"
                          required
                          value={addressLine}
                        />
                      </AddressAutofill>
                    </div>

                    {/* <div>
                      <label
                        htmlFor="vat_number"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        VAT number
                      </label>
                      <input
                        type="text"
                        id="vat_number"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="DE42313253"
                        required
                      />
                    </div> */}
                    <div>
                      <label
                        htmlFor="postal-code"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        Code Postal
                      </label>
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        onChange={(e) => setPostalCode(e.target.value)}
                        autoComplete="postal-code"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="93150"
                        required
                        value={postalCode}
                      />
                    </div>

                    <div>
                      <div className="mb-2 flex items-center gap-2">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-900 "
                        >
                          Ville*
                        </label>
                      </div>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        onChange={(e) => setCity(e.target.value)}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="Lyon"
                        required
                        value={city}
                      />
                    </div>
                    <div>
                      <div className="mb-2 flex items-center gap-2">
                        <label
                          htmlFor="region"
                          className="block text-sm font-medium text-gray-900 "
                        >
                          Région*
                        </label>
                      </div>
                      <input
                        type="text"
                        name="region"
                        id="region"
                        onChange={(e) => setRegion(e.target.value)}
                        autoComplete="address-level1"
                        // onChange={(e) => setCity(e.target.value)}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                        placeholder="Ile-de-France"
                        required
                        value={region}
                      />
                    </div>
                    <div>
                      <div className="mb-2 flex items-center gap-2">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-900 "
                        >
                          Pays*
                        </label>
                      </div>
                      <select
                        id="select-country-input-3"
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      >
                        <option selected value="FR">
                          France
                        </option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="phone-number"
                        className="mb-2 block text-sm font-medium text-gray-900 "
                      >
                        Numéro de téléphone
                      </label>
                      <div className="flex items-center">
                        <button
                          id="dropdown-phone-button-3"
                          data-dropdown-toggle="dropdown-phone-3"
                          className="z-10 inline-flex shrink-0 items-center rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100    "
                          type="button"
                        >
                          <svg
                            className="me-2 h-4 w-4"
                            fill="none"
                            viewBox="0 0 20 15"
                          >
                            <rect
                              width="19.1"
                              height="13.5"
                              x=".25"
                              y=".75"
                              fill="#fff"
                              stroke="#F5F5F5"
                              strokeWidth=".5"
                              rx="1.75"
                            />
                            <mask
                              id="a"
                              style={{ maskType: "luminance" }}
                              width="20"
                              height="15"
                              x="0"
                              y="0"
                              maskUnits="userSpaceOnUse"
                            >
                              <rect
                                width="19.1"
                                height="13.5"
                                x=".25"
                                y=".75"
                                fill="#fff"
                                stroke="#fff"
                                stroke-width=".5"
                                rx="1.75"
                              />
                            </mask>
                            <g mask="url(#a)">
                              <path
                                fill="#F44653"
                                d="M13.067.5H19.6v14h-6.533z"
                              />
                              <path
                                fill="#1035BB"
                                fillRule="evenodd"
                                d="M0 14.5h6.533V.5H0v14z"
                                clipRule="evenodd"
                              />
                            </g>
                          </svg>
                          +33
                          <svg
                            className="-me-0.5 ms-2 h-4 w-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 9-7 7-7-7"
                            />
                          </svg>
                        </button>
                        <div
                          id="dropdown-phone-3"
                          className="z-10 hidden w-56 divide-y divide-gray-100 rounded-lg bg-white shadow "
                        >
                          <ul
                            className="p-2 text-sm font-medium text-gray-700 "
                            aria-labelledby="dropdown-phone-button-2"
                          >
                            <li>
                              <button
                                type="button"
                                className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                                role="menuitem"
                              >
                                <span className="inline-flex items-center">
                                  <svg
                                    className="me-2 h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 20 15"
                                  >
                                    <rect
                                      width="19.1"
                                      height="13.5"
                                      x=".25"
                                      y=".75"
                                      fill="#fff"
                                      stroke="#F5F5F5"
                                      strokeWidth=".5"
                                      rx="1.75"
                                    />
                                    <mask
                                      id="a"
                                      style={{ maskType: "luminance" }}
                                      width="20"
                                      height="15"
                                      x="0"
                                      y="0"
                                      maskUnits="userSpaceOnUse"
                                    >
                                      <rect
                                        width="19.1"
                                        height="13.5"
                                        x=".25"
                                        y=".75"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width=".5"
                                        rx="1.75"
                                      />
                                    </mask>
                                    <g mask="url(#a)">
                                      <path
                                        fill="#F44653"
                                        d="M13.067.5H19.6v14h-6.533z"
                                      />
                                      <path
                                        fill="#1035BB"
                                        fillRule="evenodd"
                                        d="M0 14.5h6.533V.5H0v14z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                  France (+33)
                                </span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                                role="menuitem"
                              >
                                <span className="inline-flex items-center">
                                  <svg
                                    className="me-2 h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 20 15"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                    <mask
                                      id="a"
                                      style={{ maskType: "luminance" }}
                                      width="20"
                                      height="15"
                                      x="0"
                                      y="0"
                                      maskUnits="userSpaceOnUse"
                                    >
                                      <rect
                                        width="19.6"
                                        height="14"
                                        y=".5"
                                        fill="#fff"
                                        rx="2"
                                      />
                                    </mask>
                                    <g mask="url(#a)">
                                      <path
                                        fill="#D02F44"
                                        fillRule="evenodd"
                                        d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                                        clipRule="evenodd"
                                      />
                                      <path
                                        fill="#46467F"
                                        d="M0 .5h8.4v6.533H0z"
                                      />
                                      <g filter="url(#filter0_d_343_121520)">
                                        <path
                                          fill="url(#paint0_linear_343_121520)"
                                          fillRule="evenodd"
                                          d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467. 0 000 .933zm1.4.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466a.467. 0 100-.933.467. 0 000 .933zm-1.4-.466a.467. 0 11-.933 0 .467. 0 01.933 0zM1.4 4.233 a.467. 0 100-.933.467. 0 000 .933zm1.4.467 a.467. 0 11-.933 0 .467. 0 01.933 0zm1.4.467 a.467. 0 100-.934.467. 0 000 .934zM6.533 4.7 a.467. 0 11-.933 0 .467. 0 01.933 0zM7 6.1 a.467. 0 100-.933.467. 0 000 .933zm-1.4-.467 a.467. 0 11-.933 0 .467. 0 01.933 0zM3.267 6.1 a.467. 0 100-.933.467. 0 000 .933zm-1.4-.467 a.467. 0 11-.934 0 .467. 0 01.934 0z"
                                          clipRule="evenodd"
                                        />
                                      </g>
                                    </g>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_343_121520"
                                        x1=".933"
                                        x2=".933"
                                        y1="1.433"
                                        y2="6.1"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#fff" />
                                        <stop offset="1" stopColor="#F0F0F0" />
                                      </linearGradient>
                                      <filter
                                        id="filter0_d_343_121520"
                                        width="6.533"
                                        height="5.667"
                                        x=".933"
                                        y="1.433"
                                        colorInterpolationFilters="sRGB"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feFlood
                                          floodOpacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          result="hardAlpha"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        />
                                        <feOffset dy="1" />
                                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                        <feBlend
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_343_121520"
                                        />
                                        <feBlend
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_343_121520"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                  United Kingdom (+44)
                                </span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                                role="menuitem"
                              >
                                <span className="inline-flex items-center">
                                  <svg
                                    className="me-2 h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 20 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                    <mask
                                      id="a"
                                      style={{ maskType: "luminance" }}
                                      width="20"
                                      height="15"
                                      x="0"
                                      y="0"
                                      maskUnits="userSpaceOnUse"
                                    >
                                      <rect
                                        width="19.6"
                                        height="14"
                                        y=".5"
                                        fill="#fff"
                                        rx="2"
                                      />
                                    </mask>
                                    <g mask="url(#a)">
                                      <path
                                        fill="#0A17A7"
                                        d="M0 .5h19.6v14H0z"
                                      />
                                      <path
                                        fill="#fff"
                                        stroke="#fff"
                                        strokeWidth=".667"
                                        d="M0 .167h-.901l.684.586 3.15 2.7v.609L-.194 6.295l-.14.1v1.24l.51-.319L3.83 5.033h.73L7.7 7.276a.488.488 0 00.601-.767L5.467 4.08v-.608l2.987-2.134a.667.667 0 00.28-.543V-.1l-.51.318L4.57 2.5h-.73L.66.229.572.167H0z"
                                      />
                                      <path
                                        fill="url(#paint0_linear_374_135177)"
                                        fillRule="evenodd"
                                        d="M0 2.833V4.7h3.267v2.133c0 .369.298.667.666.667h.534a.667.667 0 00.666-.667V4.7H8.2a.667.667 0 00.667-.667V3.5a.667.667 0 00-.667-.667H5.133V.5H3.267v2.333H0z"
                                        clipRule="evenodd"
                                      />
                                      <path
                                        fill="url(#paint1_linear_374_135177)"
                                        fillRule="evenodd"
                                        d="M0 3.3h3.733V.5h.934v2.8H8.4v.933H4.667v2.8h-.934v-2.8H0V3.3z"
                                        clipRule="evenodd"
                                      />
                                      <path
                                        fill="#fff"
                                        fillRule="evenodd"
                                        d="M4.2 11.933l-.823.433.157-.916-.666-.65.92-.133.412-.834.411.834.92.134-.665.649.157.916-.823-.433zm9.8.7l-.66.194.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.194zm0-8.866l-.66.193.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.193zm2.8 2.8l-.66.193.193-.66-.193-.66.66.193.66-.193-.193.66.193.66-.66-.193zm-5.6.933l-.66.193.193-.66-.193-.66.66.194.66-.194-.193.66.193.66-.66-.193zm4.2 1.167l-.33.096.096-.33-.096-.33.33.097.33-.097-.097.33.097.33-.33-.096z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_374_135177"
                                        x1="0"
                                        x2="0"
                                        y1=".5"
                                        y2="7.5"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#fff" />
                                        <stop offset="1" stopColor="#F0F0F0" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint1_linear_374_135177"
                                        x1="0"
                                        x2="0"
                                        y1=".5"
                                        y2="7.033"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#FF2E3B" />
                                        <stop offset="1" stopColor="#FC0D1B" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  Australia (+61)
                                </span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                                role="menuitem"
                              >
                                <span className="inline-flex items-center">
                                  <svg
                                    className="me-2 h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 20 15"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                    <mask
                                      id="a"
                                      style={{ maskType: "luminance" }}
                                      width="20"
                                      height="15"
                                      x="0"
                                      y="0"
                                      maskUnits="userSpaceOnUse"
                                    >
                                      <rect
                                        width="19.6"
                                        height="14"
                                        y=".5"
                                        fill="#fff"
                                        rx="2"
                                      />
                                    </mask>
                                    <g mask="url(#a)">
                                      <path
                                        fill="#262626"
                                        fillRule="evenodd"
                                        d="M0 5.167h19.6V.5H0v4.667z"
                                        clipRule="evenodd"
                                      />
                                      <g filter="url(#filter0_d_374_135180)">
                                        <path
                                          fill="#F01515"
                                          fillRule="evenodd"
                                          d="M0 9.833h19.6V5.167H0v4.666z"
                                          clipRule="evenodd"
                                        />
                                      </g>
                                      <g filter="url(#filter1_d_374_135180)">
                                        <path
                                          fill="#FFD521"
                                          fillRule="evenodd"
                                          d="M0 14.5h19.6V9.833H0V14.5z"
                                          clipRule="evenodd"
                                        />
                                      </g>
                                    </g>
                                    <defs>
                                      <filter
                                        id="filter0_d_374_135180"
                                        width="19.6"
                                        height="4.667"
                                        x="0"
                                        y="5.167"
                                        colorInterpolationFilters="sRGB"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feFlood
                                          floodOpacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          result="hardAlpha"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        />
                                        <feOffset />
                                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                        <feBlend
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_374_135180"
                                        />
                                        <feBlend
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_374_135180"
                                          result="shape"
                                        />
                                      </filter>
                                      <filter
                                        id="filter1_d_374_135180"
                                        width="19.6"
                                        height="4.667"
                                        x="0"
                                        y="9.833"
                                        colorInterpolationFilters="sRGB"
                                        filterUnits="userSpaceOnUse"
                                      >
                                        <feFlood
                                          floodOpacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          result="hardAlpha"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        />
                                        <feOffset />
                                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                        <feBlend
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_374_135180"
                                        />
                                        <feBlend
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_374_135180"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                  Germany (+49)
                                </span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                                role="menuitem"
                              >
                                <span className="inline-flex items-center">
                                  <svg
                                    className="me-2 h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 20 15"
                                  >
                                    <rect
                                      width="19.1"
                                      height="13.5"
                                      x=".25"
                                      y=".75"
                                      fill="#fff"
                                      stroke="#F5F5F5"
                                      strokeWidth=".5"
                                      rx="1.75"
                                    />
                                    <mask
                                      id="a"
                                      style={{ maskType: "luminance" }}
                                      width="20"
                                      height="15"
                                      x="0"
                                      y="0"
                                      maskUnits="userSpaceOnUse"
                                    >
                                      <rect
                                        width="19.1"
                                        height="13.5"
                                        x=".25"
                                        y=".75"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width=".5"
                                        rx="1.75"
                                      />
                                    </mask>
                                    <g mask="url(#a)">
                                      <path
                                        fill="#F44653"
                                        d="M13.067.5H19.6v14h-6.533z"
                                      />
                                      <path
                                        fill="#1035BB"
                                        fillRule="evenodd"
                                        d="M0 14.5h6.533V.5H0v14z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                  France (+33)
                                </span>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="relative w-full">
                          <input
                            type="text"
                            id="phone-input"
                            className="z-20 block w-full rounded-e-lg border border-s-0 border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black  "
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            placeholder="06 36 47 99 22"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* add-new-adress */}
                    {/* <div className="sm:col-span-2">
                      <button
                        type="submit"
                        className="flex w-full items-center justify-center gap-2 rounded-lg border border-gray-200 bg-black px-5 py-2.5 text-sm font-medium  MaintenanceBase hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100      "
                      >
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 12h14m-7 7V5"
                          />
                        </svg>
                        Add new address
                      </button>
                    </div> */}
                  </div>
                </div>

                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 ">
                    Paiement
                  </h3>

                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="credit-card"
                            aria-describedby="credit-card-text"
                            type="radio"
                            name="payment-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                            checked
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="credit-card"
                            className="font-medium leading-none text-gray-900 "
                          >
                            Carte de crédit
                          </label>
                          <p
                            id="credit-card-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Paiement sécurisé et encrypté
                          </p>
                        </div>
                      </div>

                      {/* <div className="mt-4 flex items-center gap-2">
                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Delete
                        </button>

                        <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Edit
                        </button>
                      </div> */}
                    </div>

                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="pay-on-delivery"
                            aria-describedby="pay-on-delivery-text"
                            type="radio"
                            name="payment-method"
                            value=""
                            disabled
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="pay-on-delivery"
                            className="font-medium leading-none text-gray-900 "
                          >
                            Apple Pay
                          </label>
                          <p
                            id="pay-on-delivery-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Indisponible
                          </p>
                        </div>
                      </div>

                      {/* <div className="mt-4 flex items-center gap-2">
                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Delete
                        </button>

                        <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Edit
                        </button>
                      </div> */}
                    </div>

                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="paypal-2"
                            aria-describedby="paypal-text"
                            type="radio"
                            name="payment-method"
                            value=""
                            disabled
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="paypal-2"
                            className="font-medium leading-none text-gray-900 "
                          >
                            Paypal
                          </label>
                          <p
                            id="paypal-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Indisponible
                          </p>
                        </div>
                      </div>

                      {/* <div className="mt-4 flex items-center gap-2">
                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Delete
                        </button>

                        <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                        <button
                          type="button"
                          className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                        >
                          Edit
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* delivery-methods */}
                {/* <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 ">
                    Delivery Methods
                  </h3>

                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="dhl"
                            aria-describedby="dhl-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                            checked
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="dhl"
                            className="font-medium leading-none text-gray-900 "
                          >
                            $15 - DHL Fast Delivery
                          </label>
                          <p
                            id="dhl-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Get it by Tomorrow
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="fedex"
                            aria-describedby="fedex-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="fedex"
                            className="font-medium leading-none text-gray-900 "
                          >
                            Free Delivery - FedEx
                          </label>
                          <p
                            id="fedex-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Get it by Friday, 13 Dec 2023
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="express"
                            aria-describedby="express-text"
                            type="radio"
                            name="delivery-method"
                            value=""
                            className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          />
                        </div>

                        <div className="ms-4 text-sm">
                          <label
                            htmlFor="express"
                            className="font-medium leading-none text-gray-900 "
                          >
                            $49 - Express Delivery
                          </label>
                          <p
                            id="express-text"
                            className="mt-1 text-xs font-normal text-gray-500 "
                          >
                            Get it today
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div>
                  <label
                    htmlFor="voucher"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Enter un code promotionnel ou une carte cadeau:
                  </label>
                  <div className="flex max-w-md items-center gap-4">
                    <input
                      type="text"
                      id="voucher"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder=""
                      required
                    />
                    <button
                      type="button"
                      className="flex items-center justify-center rounded-lg bg-black px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-black hover:scale-105 duration-200"
                    >
                      Appliquer
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
                <div className="flow-root">
                  <div className="-my-3 divide-y divide-gray-200 ">
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 ">
                        Articles
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        {getCartTotal()}€
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 ">
                        Frais de livraison
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        {getCartTotalDelivery() - getCartTotal()}€
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 ">
                        Frais de services
                      </dt>
                      <dd className="text-base font-medium text-green-500">
                        Offerts
                      </dd>
                    </dl>
                    {/* <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 ">
                        Tax
                      </dt>
                      <dd className="text-base font-medium text-gray-900 ">
                        $199
                      </dd>
                    </dl> */}

                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-bold text-gray-900 ">
                        Total
                      </dt>
                      <dd className="text-base font-bold text-gray-900 ">
                        {getCartTotalDelivery()}€
                      </dd>
                    </dl>
                  </div>
                </div>

                <div className="space-y-3">
                  <div className="w-full h-12  mt-5 flex items-center">
                    <input
                      type="checkbox"
                      checked={cgvAccepted}
                      onChange={() => setCgvAccepted((prev) => !prev)}
                      name=""
                      id=""
                      className="rounded-sm bg-transparent ring-1 focus:ring-2 ring-gray-400 border border-gray-300 w-5 h-5 focus:ring-gray-600 text-black"
                    />
                    <p className="text-xs ml-2 text-gray-600">
                      J'accepte les conditions générales de ventes de No Context
                      et affirme en avoir pris conscience.
                    </p>
                  </div>
                  <p className="text-xs w-full text-center text-red-600">
                    {error}
                  </p>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-lg bg-black px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-black hover:scale-105 duration-200"
                    onClick={handlePay}
                    disabled={isLoading}
                  >
                    {isLoading ? "Chargement.." : "Payer"}
                  </button>

                  {/* <p className="text-sm font-normal text-gray-500 ">
                    One or more items in your cart require an account.{" "}
                    <a
                      href="#"
                      title=""
                      className="font-medium text-primary-700 underline hover:no-underline"
                    >
                      Sign in or create an account now.
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </form>
        </section>
      )}
      {/* CHECKOUT SECTION */}
      {/* <section className="">
        <form action="#" className="">
          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div className="min-w-0 flex-1 space-y-8">
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-900 ">
                  Delivery Details
                </h2>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="your_name"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      Your name
                    </label>
                    <input
                      type="text"
                      id="your_name"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder="Bonnie Green"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="your_email"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      Your email*
                    </label>
                    <input
                      type="email"
                      id="your_email"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder="name@flowbite.com"
                      required
                    />
                  </div>
                  <div>
                    <div className="mb-2 flex items-center gap-2">
                      <label
                        htmlFor="select-country-input-3"
                        className="block text-sm font-medium text-gray-900 "
                      >
                        Country*
                      </label>
                    </div>
                    <select
                      id="select-country-input-3"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                    >
                      <option selected>United States</option>
                      <option value="AS">Australia</option>
                      <option value="FR">France</option>
                      <option value="ES">Spain</option>
                      <option value="UK">United Kingdom</option>
                    </select>
                  </div>
                  <div>
                    <div className="mb-2 flex items-center gap-2">
                      <label
                        htmlFor="select-city-input-3"
                        className="block text-sm font-medium text-gray-900 "
                      >
                        City*
                      </label>
                    </div>
                    <select
                      id="select-city-input-3"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                    >
                      <option selected>San Francisco</option>
                      <option value="NY">New York</option>
                      <option value="LA">Los Angeles</option>
                      <option value="CH">Chicago</option>
                      <option value="HU">Houston</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="phone-input-3"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      Phone Number*
                    </label>
                    <div className="flex items-center">
                      <button
                        id="dropdown-phone-button-3"
                        data-dropdown-toggle="dropdown-phone-3"
                        className="z-10 inline-flex shrink-0 items-center rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100    "
                        type="button"
                      >
                        <svg
                          fill="none"
                          aria-hidden="true"
                          className="me-2 h-4 w-4"
                          viewBox="0 0 20 15"
                        >
                          <rect
                            width="19.6"
                            height="14"
                            y=".5"
                            fill="#fff"
                            rx="2"
                          />
                          <mask
                            id="a"
                            style={{ maskType: "luminance" }}
                            width="20"
                            height="15"
                            x="0"
                            y="0"
                            maskUnits="userSpaceOnUse"
                          >
                            <rect
                              width="19.6"
                              height="14"
                              y=".5"
                              fill="#fff"
                              rx="2"
                            />
                          </mask>
                          <g mask="url(#a)">
                            <path
                              fill="#D02F44"
                              fillRule="evenodd"
                              d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                              clipRule="evenodd"
                            />
                            <path fill="#46467F" d="M0 .5h8.4v6.533H0z" />
                            <g filter="url(#filter0_d_343_121520)">
                              <path
                                fill="url(#paint0_linear_343_121520)"
                                fillRule="evenodd"
                                d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.466a.467.467 0 11-.933 0 .467.467 0 01.933 0zM1.4 4.233a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM6.533 4.7a.467.467 0 11-.933 0 .467.467 0 01.933 0zM7 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.933 0 .467.467 0 01.933 0zM3.267 6.1a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0z"
                                clipRule="evenodd"
                              />
                            </g>
                          </g>
                          <defs>
                            <linearGradient
                              id="paint0_linear_343_121520"
                              x1=".933"
                              x2=".933"
                              y1="1.433"
                              y2="6.1"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#fff" />
                              <stop offset="1" stopColor="#F0F0F0" />
                            </linearGradient>
                            <filter
                              id="filter0_d_343_121520"
                              width="6.533"
                              height="5.667"
                              x=".933"
                              y="1.433"
                              colorInterpolationFilters="sRGB"
                              filterUnits="userSpaceOnUse"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                result="hardAlpha"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              />
                              <feOffset dy="1" />
                              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                              <feBlend
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_343_121520"
                              />
                              <feBlend
                                in="SourceGraphic"
                                in2="effect1_dropShadow_343_121520"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                        +1
                        <svg
                          className="-me-0.5 ms-2 h-4 w-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 9-7 7-7-7"
                          />
                        </svg>
                      </button>
                      <div
                        id="dropdown-phone-3"
                        className="z-10 hidden w-56 divide-y divide-gray-100 rounded-lg bg-white shadow "
                      >
                        <ul
                          className="p-2 text-sm font-medium text-gray-700 "
                          aria-labelledby="dropdown-phone-button-2"
                        >
                          <li>
                            <button
                              type="button"
                              className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                              role="menuitem"
                            >
                              <span className="inline-flex items-center">
                                <svg
                                  fill="none"
                                  aria-hidden="true"
                                  className="me-2 h-4 w-4"
                                  viewBox="0 0 20 15"
                                >
                                  <rect
                                    width="19.6"
                                    height="14"
                                    y=".5"
                                    fill="#fff"
                                    rx="2"
                                  />
                                  <mask
                                    id="a"
                                    style={{ maskType: "luminance" }}
                                    width="20"
                                    height="15"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                  </mask>
                                  <g mask="url(#a)">
                                    <path
                                      fill="#D02F44"
                                      fillRule="evenodd"
                                      d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                                      clipRule="evenodd"
                                    />
                                    <path
                                      fill="#46467F"
                                      d="M0 .5h8.4v6.533H0z"
                                    />
                                    <g filter="url(#filter0_d_343_121520)">
                                      <path
                                        fill="url(#paint0_linear_343_121520)"
                                        fillRule="evenodd"
                                        d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467 a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466 a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.466 a.467.467 0 11-.933 0 .467.467 0 01.933 0zM1.4 4.233 a.467.467 0 100-.933.467.467 0 000 .933zm1.4.467 a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467 a.467.467 0 100-.934.467.467 0 000 .934zM6.533 4.7 a.467.467 0 11-.933 0 .467.467 0 01.933 0zM7 6.1 a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467 a.467.467 0 11-.933 0 .467.467 0 01.933 0zM3.267 6.1 a.467.467 0 100-.933.467.467 0 000 .933zm-1.4-.467 a.467. 0 11-.934 0 .467.467 0 01.934 0z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_343_121520"
                                      x1=".933"
                                      x2=".933"
                                      y1="1.433"
                                      y2="6.1"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#fff" />
                                      <stop offset="1" stopColor="#F0F0F0" />
                                    </linearGradient>
                                    <filter
                                      id="filter0_d_343_121520"
                                      width="6.533"
                                      height="5.667"
                                      x=".933"
                                      y="1.433"
                                      colorInterpolationFilters="sRGB"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        result="hardAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      />
                                      <feOffset dy="1" />
                                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                      <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_343_121520"
                                      />
                                      <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_343_121520"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                                United States (+1)
                              </span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                              role="menuitem"
                            >
                              <span className="inline-flex items-center">
                                <svg
                                  className="me-2 h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 20 15"
                                >
                                  <rect
                                    width="19.6"
                                    height="14"
                                    y=".5"
                                    fill="#fff"
                                    rx="2"
                                  />
                                  <mask
                                    id="a"
                                    style={{ maskType: "luminance" }}
                                    width="20"
                                    height="15"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                  </mask>
                                  <g mask="url(#a)">
                                    <path
                                      fill="#D02F44"
                                      fillRule="evenodd"
                                      d="M19.6.5H0v.933h19.6V.5zm0 1.867H0V3.3h19.6v-.933zM0 4.233h19.6v.934H0v-.934zM19.6 6.1H0v.933h19.6V6.1zM0 7.967h19.6V8.9H0v-.933zm19.6 1.866H0v.934h19.6v-.934zM0 11.7h19.6v.933H0V11.7zm19.6 1.867H0v.933h19.6v-.933z"
                                      clipRule="evenodd"
                                    />
                                    <path
                                      fill="#46467F"
                                      d="M0 .5h8.4v6.533H0z"
                                    />
                                    <g filter="url(#filter0_d_343_121520)">
                                      <path
                                        fill="url(#paint0_linear_343_121520)"
                                        fillRule="evenodd"
                                        d="M1.867 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.866 0a.467.467 0 11-.933 0 .467.467 0 01.933 0zm1.4.467a.467.467 0 100-.934.467.467 0 000 .934zM7.467 1.9a.467.467 0 11-.934 0 .467.467 0 01.934 0zM2.333 3.3a.467.467 0 100-.933.467.467 0 000 .933zm2.334-.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm1.4.467a.467.467 0 100-.933.467. 0 000 .933zm1.4.467a.467.467 0 11-.934 0 .467.467 0 01.934 0zm-2.334.466a.467. 0 100-.933.467. 0 000 .933zm-1.4-.466a.467. 0 11-.933 0 .467. 0 01.933 0zM1.4 4.233 a.467. 0 100-.933.467. 0 000 .933zm1.4.467 a.467. 0 11-.933 0 .467. 0 01.933 0zm1.4.467 a.467. 0 100-.934.467. 0 000 .934zM6.533 4.7 a.467. 0 11-.933 0 .467. 0 01.933 0zM7 6.1 a.467. 0 100-.933.467. 0 000 .933zm-1.4-.467 a.467. 0 11-.933 0 .467. 0 01.933 0zM3.267 6.1 a.467. 0 100-.933.467. 0 000 .933zm-1.4-.467 a.467. 0 11-.934 0 .467. 0 01.934 0z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_343_121520"
                                      x1=".933"
                                      x2=".933"
                                      y1="1.433"
                                      y2="6.1"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#fff" />
                                      <stop offset="1" stopColor="#F0F0F0" />
                                    </linearGradient>
                                    <filter
                                      id="filter0_d_343_121520"
                                      width="6.533"
                                      height="5.667"
                                      x=".933"
                                      y="1.433"
                                      colorInterpolationFilters="sRGB"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        result="hardAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      />
                                      <feOffset dy="1" />
                                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                      <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_343_121520"
                                      />
                                      <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_343_121520"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                                United Kingdom (+44)
                              </span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                              role="menuitem"
                            >
                              <span className="inline-flex items-center">
                                <svg
                                  className="me-2 h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 20 15"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="19.6"
                                    height="14"
                                    y=".5"
                                    fill="#fff"
                                    rx="2"
                                  />
                                  <mask
                                    id="a"
                                    style={{ maskType: "luminance" }}
                                    width="20"
                                    height="15"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                  </mask>
                                  <g mask="url(#a)">
                                    <path fill="#0A17A7" d="M0 .5h19.6v14H0z" />
                                    <path
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth=".667"
                                      d="M0 .167h-.901l.684.586 3.15 2.7v.609L-.194 6.295l-.14.1v1.24l.51-.319L3.83 5.033h.73L7.7 7.276a.488.488 0 00.601-.767L5.467 4.08v-.608l2.987-2.134a.667.667 0 00.28-.543V-.1l-.51.318L4.57 2.5h-.73L.66.229.572.167H0z"
                                    />
                                    <path
                                      fill="url(#paint0_linear_374_135177)"
                                      fillRule="evenodd"
                                      d="M0 2.833V4.7h3.267v2.133c0 .369.298.667.666.667h.534a.667.667 0 00.666-.667V4.7H8.2a.667.667 0 00.667-.667V3.5a.667.667 0 00-.667-.667H5.133V.5H3.267v2.333H0z"
                                      clipRule="evenodd"
                                    />
                                    <path
                                      fill="url(#paint1_linear_374_135177)"
                                      fillRule="evenodd"
                                      d="M0 3.3h3.733V.5h.934v2.8H8.4v.933H4.667v2.8h-.934v-2.8H0V3.3z"
                                      clipRule="evenodd"
                                    />
                                    <path
                                      fill="#fff"
                                      fillRule="evenodd"
                                      d="M4.2 11.933l-.823.433.157-.916-.666-.65.92-.133.412-.834.411.834.92.134-.665.649.157.916-.823-.433zm9.8.7l-.66.194.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.194zm0-8.866l-.66.193.194-.66-.194-.66.66.193.66-.193-.193.66.193.66-.66-.193zm2.8 2.8l-.66.193.193-.66-.193-.66.66.193.66-.193-.193.66.193.66-.66-.193zm-5.6.933l-.66.193.193-.66-.193-.66.66.194.66-.194-.193.66.193.66-.66-.193zm4.2 1.167l-.33.096.096-.33-.096-.33.33.097.33-.097-.097.33.097.33-.33-.096z"
                                      clipRule="evenodd"
                                    />
                                  </g>
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_374_135177"
                                      x1="0"
                                      x2="0"
                                      y1=".5"
                                      y2="7.5"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#fff" />
                                      <stop offset="1" stopColor="#F0F0F0" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint1_linear_374_135177"
                                      x1="0"
                                      x2="0"
                                      y1=".5"
                                      y2="7.033"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#FF2E3B" />
                                      <stop offset="1" stopColor="#FC0D1B" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                Australia (+61)
                              </span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                              role="menuitem"
                            >
                              <span className="inline-flex items-center">
                                <svg
                                  className="me-2 h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 20 15"
                                >
                                  <rect
                                    width="19.6"
                                    height="14"
                                    y=".5"
                                    fill="#fff"
                                    rx="2"
                                  />
                                  <mask
                                    id="a"
                                    style={{ maskType: "luminance" }}
                                    width="20"
                                    height="15"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                  >
                                    <rect
                                      width="19.6"
                                      height="14"
                                      y=".5"
                                      fill="#fff"
                                      rx="2"
                                    />
                                  </mask>
                                  <g mask="url(#a)">
                                    <path
                                      fill="#262626"
                                      fillRule="evenodd"
                                      d="M0 5.167h19.6V.5H0v4.667z"
                                      clipRule="evenodd"
                                    />
                                    <g filter="url(#filter0_d_374_135180)">
                                      <path
                                        fill="#F01515"
                                        fillRule="evenodd"
                                        d="M0 9.833h19.6V5.167H0v4.666z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                    <g filter="url(#filter1_d_374_135180)">
                                      <path
                                        fill="#FFD521"
                                        fillRule="evenodd"
                                        d="M0 14.5h19.6V9.833H0V14.5z"
                                        clipRule="evenodd"
                                      />
                                    </g>
                                  </g>
                                  <defs>
                                    <filter
                                      id="filter0_d_374_135180"
                                      width="19.6"
                                      height="4.667"
                                      x="0"
                                      y="5.167"
                                      colorInterpolationFilters="sRGB"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        result="hardAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      />
                                      <feOffset />
                                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                      <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_374_135180"
                                      />
                                      <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_374_135180"
                                        result="shape"
                                      />
                                    </filter>
                                    <filter
                                      id="filter1_d_374_135180"
                                      width="19.6"
                                      height="4.667"
                                      x="0"
                                      y="9.833"
                                      colorInterpolationFilters="sRGB"
                                      filterUnits="userSpaceOnUse"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        result="hardAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      />
                                      <feOffset />
                                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                      <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_374_135180"
                                      />
                                      <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_374_135180"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                                Germany (+49)
                              </span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="inline-flex w-full rounded-md px-3 py-2 text-sm text-gray-500 MaintenanceBase hover:text-gray-900  "
                              role="menuitem"
                            >
                              <span className="inline-flex items-center">
                                <svg
                                  className="me-2 h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 20 15"
                                >
                                  <rect
                                    width="19.1"
                                    height="13.5"
                                    x=".25"
                                    y=".75"
                                    fill="#fff"
                                    stroke="#F5F5F5"
                                    strokeWidth=".5"
                                    rx="1.75"
                                  />
                                  <mask
                                    id="a"
                                    style={{ maskType: "luminance" }}
                                    width="20"
                                    height="15"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                  >
                                    <rect
                                      width="19.1"
                                      height="13.5"
                                      x=".25"
                                      y=".75"
                                      fill="#fff"
                                      stroke="#fff"
                                      stroke-width=".5"
                                      rx="1.75"
                                    />
                                  </mask>
                                  <g mask="url(#a)">
                                    <path
                                      fill="#F44653"
                                      d="M13.067.5H19.6v14h-6.533z"
                                    />
                                    <path
                                      fill="#1035BB"
                                      fillRule="evenodd"
                                      d="M0 14.5h6.533V.5H0v14z"
                                      clipRule="evenodd"
                                    />
                                  </g>
                                </svg>
                                France (+33)
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          id="phone-input"
                          className="z-20 block w-full rounded-e-lg border border-s-0 border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black  "
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          placeholder="123-456-7890"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder="name@flowbite.com"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="company_name"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder="Flowbite LLC"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="vat_number"
                      className="mb-2 block text-sm font-medium text-gray-900 "
                    >
                      VAT number
                    </label>
                    <input
                      type="text"
                      id="vat_number"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                      placeholder="DE42313253"
                      required
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <button
                      type="submit"
                      className="flex w-full items-center justify-center gap-2 rounded-lg border border-gray-200 bg-black px-5 py-2.5 text-sm font-medium  MaintenanceBase hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100      "
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add new address
                    </button>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Payment
                </h3>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="credit-card"
                          aria-describedby="credit-card-text"
                          type="radio"
                          name="payment-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          checked
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="credit-card"
                          className="font-medium leading-none text-gray-900 "
                        >
                          Credit Card
                        </label>
                        <p
                          id="credit-card-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Pay with your credit card
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex items-center gap-2">
                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Delete
                      </button>

                      <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="pay-on-delivery"
                          aria-describedby="pay-on-delivery-text"
                          type="radio"
                          name="payment-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="pay-on-delivery"
                          className="font-medium leading-none text-gray-900 "
                        >
                          Payment on delivery
                        </label>
                        <p
                          id="pay-on-delivery-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          +$15 payment processing fee
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex items-center gap-2">
                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Delete
                      </button>

                      <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="paypal-2"
                          aria-describedby="paypal-text"
                          type="radio"
                          name="payment-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="paypal-2"
                          className="font-medium leading-none text-gray-900 "
                        >
                          Paypal account
                        </label>
                        <p
                          id="paypal-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Connect to your account
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex items-center gap-2">
                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Delete
                      </button>

                      <div className="h-3 w-px shrink-0 bg-gray-200 "></div>

                      <button
                        type="button"
                        className="text-sm font-medium text-gray-500 hover:text-gray-900  "
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900 ">
                  Delivery Methods
                </h3>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="dhl"
                          aria-describedby="dhl-text"
                          type="radio"
                          name="delivery-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                          checked
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="dhl"
                          className="font-medium leading-none text-gray-900 "
                        >
                          $15 - DHL Fast Delivery
                        </label>
                        <p
                          id="dhl-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Get it by Tomorrow
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="fedex"
                          aria-describedby="fedex-text"
                          type="radio"
                          name="delivery-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="fedex"
                          className="font-medium leading-none text-gray-900 "
                        >
                          Free Delivery - FedEx
                        </label>
                        <p
                          id="fedex-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Get it by Friday, 13 Dec 2023
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-lg border border-gray-200 bg-gray-50 p-4 ps-4  ">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="express"
                          aria-describedby="express-text"
                          type="radio"
                          name="delivery-method"
                          value=""
                          className="h-4 w-4 border-gray-300 bg-white text-black focus:ring-2 focus:ring-black    "
                        />
                      </div>

                      <div className="ms-4 text-sm">
                        <label
                          htmlFor="express"
                          className="font-medium leading-none text-gray-900 "
                        >
                          $49 - Express Delivery
                        </label>
                        <p
                          id="express-text"
                          className="mt-1 text-xs font-normal text-gray-500 "
                        >
                          Get it today
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="voucher"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Enter a gift card, voucher or promotional code
                </label>
                <div className="flex max-w-md items-center gap-4">
                  <input
                    type="text"
                    id="voucher"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-black focus:ring-black   "
                    placeholder=""
                    required
                  />
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 "
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md">
              <div className="flow-root">
                <div className="-my-3 divide-y divide-gray-200 ">
                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Subtotal
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      $8,094.00
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Savings
                    </dt>
                    <dd className="text-base font-medium text-green-500">0</dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Store Pickup
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      $99
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-normal text-gray-500 ">
                      Tax
                    </dt>
                    <dd className="text-base font-medium text-gray-900 ">
                      $199
                    </dd>
                  </dl>

                  <dl className="flex items-center justify-between gap-4 py-3">
                    <dt className="text-base font-bold text-gray-900 ">
                      Total
                    </dt>
                    <dd className="text-base font-bold text-gray-900 ">
                      $8,392.00
                    </dd>
                  </dl>
                </div>
              </div>

              <div className="space-y-3">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 "
                >
                  Proceed to Payment
                </button>

                <p className="text-sm font-normal text-gray-500 ">
                  One or more items in your cart require an account.{" "}
                  <a
                    href="#"
                    title=""
                    className="font-medium text-primary-700 underline hover:no-underline"
                  >
                    Sign in or create an account now.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </section> */}
    </div>
  );
}

export default NewCart;
