import React, { useState, useContext, useEffect } from "react";
import HeaderAlt from "../../components/layout/HeaderAlt";
import { AuthContext } from "../../context/auth";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import { axiosPrivate } from "../../api/axios";
import * as Loader from "react-spinners";
import { HiChevronRight } from "react-icons/hi2";
import Modal from "../../components/layout/Modal";
import NewFooter from "../../components/layout/NewFooter";
import { CartContext } from "../../context/cart";
import SideCart from "../../components/layout/SideCart"  

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const UserProfile = () => {
  const [selectedList, setSelectedList] = useState("Wishlist");
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const { userData, logoutUser } = useContext(AuthContext);
  const {sideCartOpen, setSideCartOpen} = useContext(CartContext)
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const getProductsInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get("/user/wishlist/123");
      if (response.status === 200) {
        setWishlistProducts(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true)
    await logoutUser()
    setIsLoading(false)
    navigate("/")
  }

  useEffect(() => {
    getProductsInfo();
  }, []);

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} setModalOpen={setModalOpen} title={"Déconnexion"} subTitle={"Etes-vous sûrs de vouloir vous déconnecter?"} confirmText={"Confirmer"} confirmAction={handleLogout} isLoading={isLoading}></Modal>
      <HeaderAlt backgroundColor="white" />
      <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
      <div className="w-full  mt-[5vh] lg:mt-[10vh] flex flex-col items-center">
        {/* MAIN INFO BLOCK */}
        <div className="w-full lg:w-[70%] lg:min-h-64  flex justify-between px-4 py-8 lg:p-8">
          <div className="flex">
            <img
              src={userData.info.profilePicture}
              alt=""
              className="w-24 lg:w-48 aspect-square h-24 lg:h-48 object-cover rounded-full"
            />
            <div className="flex flex-col ml-3 py-3 lg:py-7 justify-between">
              <div className="flex flex-col">
                <h1 className="text-2xl line-clamp-1">@{userData.info.username}</h1>
                <h1 className="text-xs mb-1 text-gray-500 ml-1">Novice</h1>
                <div className="flex items-center ml-1">
                  <FaLocationDot className="text-gray-700" />
                  <p className="font-bold text-sm text-gray-700">France</p>
                </div>
              </div>
              <h1 className="hidden lg:inline-block border border-gray-200 rounded-lg px-3 py-1">
                {userData.orders.length} articles achetés
              </h1>
            </div>
          </div>
          <div className="lg:border border-gray-200 rounded-lg lg:m-5 flex flex-col lg:flex-row h-fit p-2 lg:space-x-3">
            <div>
              <h1 className="font-bold">0</h1>
              <h1 className="text-xs lg:text-sm">Abonnés</h1>
            </div>
            <div>
              <h1 className="font-bold">0</h1>
              <h1 className="text-xs lg:text-sm">Abonnements</h1>
            </div>
          </div>
        </div>
        {/* SETTINGS */}
        <div className="w-full lg:w-[70%] px-4 lg:px-8 flex flex-col space-y-3">
          <Item title="Mon Compte">
            <Link to="edit" className="w-full p-2 border-b border-gray-200 rounded-sm hover:cursor-pointer hover:bg-slate-50 flex items-center justify-between">
              <p>Mes Informations</p>
              <HiChevronRight className="" />
            </Link>
            <Link to="password" className="w-full p-2 border-b border-gray-200 rounded-sm hover:cursor-pointer hover:bg-slate-50 flex items-center justify-between">
              <p>Changer Mot de Passe</p>
              <HiChevronRight className="" />
            </Link>
            <Link to="account" className="w-full p-2 border-b border-gray-200 rounded-sm hover:cursor-pointer hover:bg-slate-50 flex items-center justify-between">
              <p>Gérer mon Compte</p>
              <HiChevronRight className="" />
            </Link>
            <button className="w-full p-2 border-b border-gray-200 rounded-sm hover:cursor-pointer hover:bg-slate-50 flex items-center justify-between" onClick={() => setModalOpen(true)}>
              <p>Déconnexion</p>
              <HiChevronRight className="" />
            </button>
          </Item>
          <Item title="Mes Commandes">
            Vous n'avez passé aucune commande pour le moment.
          </Item>
          {/* <Item title="Mentions Légales">
            Avec notre service d'authenfication, ce vêtement upcyclé est livré
            avec certificat d'authenticité délivré par nos soins. Lorsque vous
            achetez une création sur notre site vous achetez une création du dit
            Créateur et non une création de la marque dont la pièce upcyclée
            provient.
          </Item>
          <Item title="Aide">
            Avec notre service d'authenfication, ce vêtement upcyclé est livré
            avec certificat d'authenticité délivré par nos soins. Lorsque vous
            achetez une création sur notre site vous achetez une création du dit
            Créateur et non une création de la marque dont la pièce upcyclée
            provient.
          </Item> */}
        </div>
        {/* WISHLIST BLOCK */}
        <div className="w-[100%] bg-slate-50 pb-20 mt-5">
          <div className="w-full flex justify-center my-5">
            <h1 className="text-2xl">Vos Coups de coeur</h1>
          </div>
          {/* STATE OPERATOR */}
          <div className="w-full  flex justify-center items-center">
            <div className="flex items-center w-[30%] justify-around h-full space-x-5 lg:space-x-0">
              <button
                className={`${
                  selectedList === "Wishlist" && "border-b-2 border-black"
                } hover:border-b-2 hover:border-black`}
                onClick={() => setSelectedList("Wishlist")}
              >
                <h1
                  className={` ${selectedList === "Wishlist" && "font-bold"}`}
                >
                  Articles
                </h1>
              </button>
              <button
                className={`${
                  selectedList === "Creators" && "border-b-2 border-black"
                } hover:border-b-2 hover:border-black`}
                onClick={() => setSelectedList("Creators")}
              >
                <h1
                  className={` ${selectedList === "Creators" && "font-bold"}`}
                >
                  Créateurs
                </h1>
              </button>
            </div>
          </div>
          {/* LIST RENDERS */}
          {selectedList === "Wishlist" ? (
            <div className="w-full min-h-64 mt-5">
              {userData.wishlist.length === 0 ? (
                <div className="w-full flex flex-col items-center pt-3">
                  <img
                    src={require("../../assets/images/manquant.png")}
                    className="w-[10%]"
                  />
                  <p className="w-2/3 lg:w-1/3 self-center text-center text-gray-500">
                    Il semblerait que vous n'ayez rien sauvegardé jusqu'à
                    présent. Allez jeter un oeil à notre catalogue !
                  </p>
                  <Link
                    className="px-3 py-2 mt-3 rounded-sm shadow-md bg-black hover:scale-[103%] duration-300"
                    to="/catalog"
                  >
                    <h1 className="text-white">Explorer</h1>
                  </Link>
                </div>
              ) : isLoading ? (
                <div className="w-full h-full flex justify-center pt-5">
                  <Loader.PuffLoader
                    color={"#000"}
                    loading={true}
                    cssOverride={override}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="mt-5"
                  />
                </div>
              ) : (
                <div className="w-full flex flex-wrap items-center lg:px-8 justify-center">
                  {wishlistProducts.map((product) => (
                    <div key={product._id} className="w-[80%] lg:w-[30%] mt-3">
                      <Link to={`/products/${product._id}`}>
                        <img
                          src={product.assets.mainImage}
                          className="w-full aspect-1"
                          alt=""
                          srcset=""
                        />
                      </Link>
                      <div className="flex items-center justify-between p-2">
                        <h1 className="text-xl">{product.name}</h1>
                        <h3>{product.price}€</h3>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : selectedList === "Creators" ? (
            <div className="w-full h-64 mt-5">
              <div className="w-full flex flex-col items-center pt-3">
                <img
                  src={require("../../assets/images/manquant.png")}
                  className="w-[10%]"
                />
                <p className="w-2/3 lg:w-1/3 self-center text-center text-gray-500">
                  Il semblerait que vous n'ayez rien sauvegardé jusqu'à présent.
                  Allez jeter un oeil à notre catalogue !
                </p>
                <Link
                  className="px-3 py-2 mt-3 rounded-sm shadow-md bg-black hover:scale-[103%] duration-300"
                  to="/catalog"
                >
                  <h1 className="text-white">Explorer</h1>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
        {/* RECCOMENDED FOR YOU */}
        {/* <div className="h-[60vh] w-screen bg-red-200">
          <div className="w-50 h-50 bg-gray-600"></div>
        </div> */}
      </div>
      <NewFooter />
    </div>
  );
};

export default UserProfile;
