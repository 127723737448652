import { X } from "react-feather";
import * as Loader from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "black",
};

export default function Modal({
  open,
  onClose,
  setModalOpen,
  title,
  subTitle,
  confirmText,
  confirmAction,
  icon,
  isLoading,
  children,
}) {
  return (
    // backdrop
    <div
      onClick={onClose}
      className={`
        fixed inset-0 flex justify-center items-center transition-colors z-50
        ${open ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
          bg-white rounded-xl shadow p-6 transition-all z-50
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        <button
          onClick={onClose}
          disabled={isLoading}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 z-50"
        >
          <X />
        </button>
        <div className="text-center w-56">
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-gray-800">{title}</h3>
            <p className="text-sm text-gray-500">{subTitle}</p>
          </div>
          <div className="flex gap-4 mt-3">
            <button
              onClick={confirmAction}
              disabled={isLoading}
              className="btn w-full hover:scale-105 duration-200 rounded-md border-gray-200 border bg-gray-100 py-2"
            >
              {isLoading ? (
                <Loader.PuffLoader
                  color={"gray"}
                  isLoading={true}
                  cssOverride={override}
                  size={20}
                  aria-label="isLoading Spinner"
                  data-testid="loader"
                  className=""
                />
              ) : (
                <p>{confirmText}</p>
              )}
            </button>
            <button
              className="btn w-full hover:scale-105 duration-200 rounded-md border-gray-200 border bg-black text-white py-2"
              disabled={isLoading}
              onClick={() => setModalOpen(false)}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
