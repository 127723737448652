import React, { useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';
import maintenanceAnimation from '../assets/lotties/order-fail.json';  // Update the path as necessary
import { Link } from 'react-router-dom';
import HeaderAlt from './layout/HeaderAlt';
import { CartContext } from '../context/cart';
import SideCart from './layout/SideCart';

function Maintenance() {
    // const history = useHistory();
    const {sideCartOpen, setSideCartOpen} = useContext(CartContext)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: maintenanceAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 relative">
            <HeaderAlt />
            <SideCart open={sideCartOpen} setOpen={setSideCartOpen} />
            <div className="text-center">
            <Lottie animationData={maintenanceAnimation} className="w-60 mt-5 mx-auto" loop={true} />
                <h1 className="text-xl font-semibold text-gray-800 mt-4">
                    Cette page est actuellement en maintenance.
                </h1>
                <p className="text-gray-600 mb-3">N'hésitez pas à revenir plus tard !</p>
                <Link 
                    to="/"
                    className="mt-3 bg-black text-white text-sm py-2 px-4 rounded-lg hover:scale-105 duration-200"
                    // onClick={() => history.push('/#')}
                    >
                    Accueil
                </Link>
            </div>
        </div>
    );
}

export default Maintenance;
